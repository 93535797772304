// Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  styled,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Switch,
  Button,
  TextField
} from "@mui/material";

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; 
import './calendarStyles.css';
import {calendar1,plusIcon,left,right,calendar2, unCheckedBox, checkedBox, upArrow, downArrow} from './assets'
import BigCalender from './BigCalender.web'

import SchedulingController, {
  Props,
} from "./SchedulingController";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { leftArrow } from "../../../../packages/blocks/email-account-registration/src/assets";
import { calender } from "../../../../packages/blocks/navigationmenu/src/assets";
import { View, TouchableOpacity, StyleSheet, Image } from "react-native";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EastIcon from '@mui/icons-material/East';
import PublicIcon from '@mui/icons-material/Public';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import AirplaneTicketOutlinedIcon from '@mui/icons-material/AirplaneTicketOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { Calendar as Bcalendar } from "@builder/component-library";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

export default class SchedulingAvailability extends SchedulingController {
  constructor(props: Props) {
    super(props);
  }
  handleDateChange = (selectedDate:any) => {
    this.setState({ date: selectedDate });
  };

  renderNotifyMembers() {
    const { notifyUsers } = this.state;
    
    return notifyUsers.map((item, index) => {
      const initials = item.name
        .split(" ")
        .map((n: any[]) => n[0])
        .join("");
  
      return (
        <TouchableOpacity key={index} style={styles.allCheck}>
          <Image source={unCheckedBox} style={styles.imageStyle} />
          {item.image ? (
            <Image source={{ uri: item.image }} style={styles.imageProfileStyle} />
          ) : (
            <View style={[styles.initialsContainer, styles.imageProfileStyle]}>
              <Typography style={styles.initialsText}>{initials}</Typography>
            </View>
          )}
          <View>
            <Typography style={styles.memberName}>{item.name}</Typography>
          </View>
        </TouchableOpacity>
      );
    });
  }
  

  renderSelectNotifyPeoples() {
    const { isNotifyContainerOpen } = this.state;
    if(this.state.shouldNotifyAlluser) return
    return (
      <View style={styles.borderBottom}>
        <TouchableOpacity
          testID="toggleNotifyUser"
          onPress={() => this.toggleNotifyUserContainer()}
          style={styles.subContainer2}
        >
          <View style={styles.flexCenter}>
            <GroupsOutlinedIcon style={styles.icons}/>
            <Typography
              style={{
                  fontSize: 16,
                  fontWeight: "400",
                  width: "80%",
                  marginLeft:18,
                  color: this.getColorForNotifyUser()
              }}
            >
              Select people to notify
            </Typography>
          </View>
          <View>
            <Image
              style={styles.arrowStyle}
              source={this.state.isNotifyContainerOpen ? upArrow : downArrow}
            />
          </View>
        </TouchableOpacity>
        {isNotifyContainerOpen && (
          <View style={{ paddingVertical: 10, paddingHorizontal:5}}>
            <View style={styles.searchContainer}>
                <SearchOutlinedIcon style={styles.icons} />
              <CustomSearchField
                style={styles.searchPlaceholder}
                placeholder="Search waitlist members"
              />
            </View>
            <TouchableOpacity
              style={styles.allCheck}
            >
              <Image
                source={this.handleAllCheckIcon()}
                style={styles.imageStyle}
              />
              <Typography 
              style={styles.selectText}>
                Select the first 10 waitlist members
              </Typography>
            </TouchableOpacity>
            {this.renderNotifyMembers()}
          </View>
        )}
      </View>
    );
  }

  renderCalenderView() {
    const { isCalenderOpen, availabilityDate } = this.state
    return(
      <View style={{ height: "inherit" }}>
        <View
          style={styles.detailsContainer}
        >
          <Typography style={styles.detailsText}>
            TIMING
          </Typography>
        </View>
        <View style={styles.calenderContainer}>
          <TouchableOpacity
            data-test-id="open-calender-btn"
            onPress={() => this.toggleCalenderOpen()}
          >
            <View
              style={styles.subContainer2}
            >
              <View style={{ flexDirection: "row" }}>
                <img style={styles.imageStyle} src={calender} />
                <View style={{ marginLeft: 30 }}>
                  <Typography
                    style={{
                      fontSize: 16,
                      color: isCalenderOpen ? "#0074BF" : "#0F172A",
                      fontWeight: "500",
                    }}
                  >
                    {this.formatDate(new Date(availabilityDate))}
                  </Typography>
                </View>
              </View>
              <Image
                style={styles.arrowStyle}
                source={isCalenderOpen ? upArrow : downArrow}
              />
            </View>
          </TouchableOpacity>
        </View>
        {isCalenderOpen && (
            <CustomBox >
              <Bcalendar
                currentDate={new Date()}
                test-test-id="calender-basic"
                style={{maxWidth:"100%"}}
                onDayPress={(day: { dateString: any }) => {
                  this.setState({
                    availabilityDate: day.dateString,
                    isCalenderOpen: false, 
                  });
                }}
                markedDates={{
                  [availabilityDate]: {
                    selected: true,
                    disableTouchEvent: true,
                    selectedColor: "#5274B5",
                    selectedTextColor: "white",
                  },
                }}
                theme={{
                  selectedDayBackgroundColor: "#5274B5",
                  selectedDayTextColor: "white",
                  dayTextColor: "#64748B",
                }}
              />
            </CustomBox>
          )}
      </View>
    )
  }

  renderTimeSlots() {
    const { timeSlot } = this.state
    if(this.state.timeSlot.length <= 0) return
    return(timeSlot.map((time, index) => (
      <View
        key={index}
        data-test-id="dateContainer"
        style={styles.dateContainer}
      >
        <View
          style={styles.subContainer}
        >
          <View style={styles.timePickerContainer}>
            {index < 1 ? (
              <Box data-test-id={`timePicker${index}`} onClick={() => { this.timePickerInputRefs[`starttime_${index}`]?.click()}}>
                <AccessTimeIcon style={styles.icons}/>
              </Box>
            ) : (
              <Box data-test-id="delete-time-slot" onClick={() => this.handleDelete(index)}>
                <DeleteOutlineIcon style={styles.deleteIcon} />
              </Box>
            )}
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <TimePicker
                data-test-id="txtInputAvailableToStartTime"
                ampm={false}
                value={moment(time.starttime, "HH:mm").toDate()}
                onChange={(date) => {
                  if (date) {
                    this.handleUpdateTimeSlot(index, "starttime", date as any);
                  }
                }}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    inputRef={(input:any) => {
                      this.timePickerInputRefs[`starttime_${index}`] = input;
                    }}
                  />
                )}
              />
            </LocalizationProvider>
            <Box
              data-test-id={`open-time-picker-starttime${index}`}
              onClick={() => {
                this.timePickerInputRefs[`starttime_${index}`]?.click();
              }}
              style={styles.flexContainer}
            >
              <Image
                style={styles.arrowStyle}
                source={downArrow}
              />
            </Box>
          </View>

          <Box style={{ marginTop: "20px" }}><EastIcon style={styles.eastIcon} /></Box>
          <View style={styles.endTimeContainer}>
            <Box data-test-id={`time-picker-endtime${index}`}
              onClick={() => {
                this.timePickerInputRefs[`endtime_${index}`]?.click();
              }}>
              <AccessTimeIcon style={styles.icons}/>
            </Box>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <TimePicker
                data-test-id="txtInputAvailableToEndTime"
                ampm={false}
                value={moment(time.endtime, "HH:mm").toDate()}
                onChange={(date) => {
                  if (date) {
                    this.handleUpdateTimeSlot(index, "endtime", date as any);
                  }
                }}
                renderInput={(params) =>  <CustomTextField
                  {...params}
                  inputRef={(input: any) => {
                    this.timePickerInputRefs[`endtime_${index}`] = input;
                  }}
                />}
              />
            </LocalizationProvider>
            <Box onClick={() => { this.timePickerInputRefs[`endtime_${index}`]?.click()}}
              style={styles.flexContainer}
              data-test-id={`open-time-picker-endtime${index}`}
            >
              <Image
                style={styles.arrowStyle}
                source={downArrow}
              />
            </Box>
          </View>
        </View>
      </View>
    )))
  }

  renderTimezoneOverlay() {
    const { showtimeZoneOverlay, timeZones, selectedTimeZone} = this.state
    return (
      <>
        <View
          data-test-id="dateContainer"
          style={styles.dateContainer}
        >
          <TouchableOpacity
            data-test-id="opentimeZoneoverlay"
            onPress={() => this.toggleShowtimeZoneOverlay()}>
            <View
              style={styles.subContainer2}
            >
              <View style={{ flexDirection: "row", alignContent: "center" }}>
                <PublicIcon style={styles.icons} />
                <View style={{ marginLeft: 25 }}>
                  <Typography
                    style={{
                      fontSize: 16,
                      color: this.state.showtimeZoneOverlay
                        ? "#0074BF"
                        : "#0F172A",
                      fontWeight: "500",
                    }}
                  >
                    {this.state.selectedTimeZone}
                  </Typography>
                </View>
              </View>
              <Image
                style={styles.arrowStyle}
                source={this.state.showtimeZoneOverlay ? upArrow : downArrow} />
            </View>
          </TouchableOpacity>
        </View>
        {showtimeZoneOverlay
          ? timeZones.map((zone, index) => (
            <View
              key={index}
              style={{
                width: "100%",
                height: 50,
                backgroundColor:
                  selectedTimeZone == zone
                    ? "#E0F2F8"
                    : "#fff",
                justifyContent: "center",
              }}
            >
              <TouchableOpacity
                data-test-id="selecttimeZone"
                onPress={() => {
                  this.setState({
                    selectedTimeZone: zone,
                    showtimeZoneOverlay: false,
                  });
                }}
              >
                <Typography style={styles.selectedZone}>
                  {zone}
                </Typography>
              </TouchableOpacity>
            </View>
          ))
          : null}
        <View style={styles.subContainer2}>
        <StyledButtonContainer>
            <CustomButton
              data-test-id="cancel"
              variant="outlined"
              className="cancelButton"
              onClick={() => {this.setState({ open: false })}}>
                Cancel
            </CustomButton>
          </StyledButtonContainer>
          <StyledButtonContainer>
            <CustomButton
              data-test-id="set-details"
              variant="contained"
              color="primary"
              className="setDetailsButton"
              onClick={() => {this.setState({ isSetDetailsOpen: true, open: false })}}>
                Set details
            </CustomButton>
          </StyledButtonContainer>
        </View>
      </>
    )
  }

  renderSelectAirportView() {
    const { isSelectAirportOpen, userSelectedAirports, allAirports} = this.state
    return(
      <View style={styles.borderBottom}>
      <TouchableOpacity
        data-test-id="select-airport"
        onPress={() => this.toggleSelectAirport()}
      >
        <View style={styles.subContainer2}>
          <View style={styles.flexCenter}>
            <FlightTakeoffIcon style={styles.icons} />
            <View style={{ marginLeft: 20 }}>
              <Typography
                style={{
                  fontSize: 16,
                  color: isSelectAirportOpen ? "#0074BF" : "#0F172A",
                  fontWeight: "500",
                }}
              >
                {userSelectedAirports && userSelectedAirports.length > 0
                  ? this.formatSelectedAirports(userSelectedAirports)
                  : "Select airport(s)"}
              </Typography>
            </View>
          </View>
          <Image
            style={styles.arrowStyle}
            source={isSelectAirportOpen ? upArrow : downArrow}
          />
        </View>
      </TouchableOpacity>
      {isSelectAirportOpen && <View style={styles.selectAirport}>
        <TouchableOpacity
          data-test-id="selectAllAirports"
          onPress={() => this.toggleSelectAllAirports()}
          style={styles.selectAllAirport}
        >
          <Image
          source={this.handleAllCheckIcon()}
          style={styles.allCheckImage}
        />
          <Typography style={styles.airportText}>All airports</Typography>
        </TouchableOpacity>
        {allAirports.length >= 1 &&
          allAirports.map((item, index) => (
            <TouchableOpacity
              data-test-id={`selectSingleAirport-${index}`}
              onPress={() => this.handleSelectAirport(item)}
              key={index}
              style={styles.selectAllAirport}
            >
              <Image
              source={
                this.handleAirportCheckbox(item.service_subcategory_id)
                  ? checkedBox
                  : unCheckedBox
              }
              style={styles.checked}
            /> 
              <Typography style={styles.subCate}>
                {item.service_subcategory_name}
              </Typography>
            </TouchableOpacity>
          ))}
        <TouchableOpacity
          data-test-id="edit-airports"
          onPress={() => this.props.navigation.navigate("AirportSettings")}
          style={styles.selectAllAirport}
        >
          <EditOutlinedIcon name="edit" style={styles.editIcon} />
          <Typography style={styles.editAirport}>Edit airports</Typography>
        </TouchableOpacity>
      </View>}
    </View>
    )
  }

  renderSelectTestsView() {
    const { allTests, isSelectTestTypeOpen, userSelectedTests} = this.state
    return (
      <View style={styles.borderBottom}>
        <TouchableOpacity
          data-test-id="select-tests"
          onPress={() => this.toggleSelectTest()}
        >
          <View style={styles.subContainer2}>
            <View style={styles.flexCenter}>
              <AirplaneTicketOutlinedIcon style={styles.icons} />
              <View style={{ marginLeft: 20 }}>
                <Typography
                  style={{
                    fontSize: 16,
                    color: isSelectTestTypeOpen ? "#0074BF" : "#0F172A",
                    fontWeight: "500",
                  }}
                >
                  {userSelectedTests && userSelectedTests.length > 0
                    ? this.formatSelectedTest(userSelectedTests)
                    : "Select tests(s)"}
                </Typography>
              </View>
            </View>
            <Image
              style={styles.arrowStyle}
              source={isSelectTestTypeOpen ? upArrow : downArrow}
            />
          </View>
        </TouchableOpacity>
        {
          isSelectTestTypeOpen && <View style={styles.padding}>
            <TouchableOpacity
              data-test-id="selectAllTests"
              onPress={() => this.toggleSelectAllTests()}
              style={styles.selectAllTest}
            >
              <Image
                source={this.handleAllCheckIconforTests()}
                style={styles.checked}
              />
              <Typography style={styles.airportText}>All tests</Typography>
            </TouchableOpacity>
            {allTests.length >= 1 &&
              allTests.map((item, index) => (
                <TouchableOpacity
                  data-test-id={`select-single-test-${index}`}
                  onPress={() => this.handleSelectTests(item)}
                  key={index}
                  style={styles.singleTest}
                >
                  <Image
                    source={
                      this.handleTestCheckbox(item.id) ? checkedBox : unCheckedBox
                    }
                    style={styles.checked}
                  />
                  <Typography style={styles.airportText}>
                    {item.designation}
                  </Typography>
                </TouchableOpacity>
              ))}
              <TouchableOpacity
                data-test-id="redirectToEditTests"
                onPress={() => this.props.navigation.navigate("TestSettings")}
                style={styles.settings}
              >
              <EditOutlinedIcon name="edit" style={styles.editIcon} />
              <Typography style={styles.editAirport}>Edit tests</Typography>
            </TouchableOpacity>
          </View>
        }
      </View>
    )
  }

  render() {
    return (
        <>
        <Box sx={webStyle.headerContainer}>
          <Typography sx={webStyle.headerText}>Change password</Typography>
          <Box style={{display:'flex',alignItems:'center',gap:'20px'}}>
          <Typography sx={webStyle.headerText1}>Sync calendars</Typography>
          
            
          <Typography sx={webStyle.headerText1}>
          
          <img src={calendar1} style={{width:'21px',height:'21px'}}/>
            Today
            </Typography>
          <Typography data-test-id="new-availability" sx={webStyle.headerText2} onClick={() => this.setState({ open: true })}>
          <img src={plusIcon} style={{width:'21px',height:'21px'}}/>
            New availability</Typography>
          </Box>
        </Box>
    <Grid container spacing={2} style={{padding:'50px 50px 50px 50px'}} >
      <Grid item xs={8} sm={4} lg={4} md={4} >
        
        <Box>
        <div style={calendarStyles}>
              <Calendar onChange={this.handleDateChange} value={this.state.date} />
            </div>    
          </Box>



        <StyledContainer style={{padding:'20px 24px 20px 24px'}}>
          <Box style={{display:'flex',justifyContent:'space-between',width:'100%'}}>

            <Box style={{color:'#001C55',fontWeight:700,fontSize:'16px',fontFamily:'Montserrat'}}>
        Upcoming booked slots
            </Box>
        <Box style={{display:'flex',alignItems:'center',gap:'20px'}}>
        <img src={left} style={{width:'20px',height:'20px'}}/>
        <img src={right} style={{width:'20px',height:'20px'}}/>
        </Box>
        </Box>

        <Box style={{display:'contents'}}>
        <img src={calendar2} style={{width:'60px',height:'60px',marginTop:'80px',marginBottom:'20px'}}/>
        <Typography sx={webStyle.text}>Nothing booked yet!</Typography>
        <Typography sx={webStyle.text1}>Once your availabilities are booked,<br/> they'll appear here.</Typography>
        </Box>
        </StyledContainer>
      </Grid>

      <Grid item xs={8} sm={8} lg={8} md={8}>
        <StyledContainerBigCal>
          <BigCalender navigation={this.props.navigation} id={""}/>
        </StyledContainerBigCal>
      </Grid>
  
    </Grid>
    <Dialog open={this.state.open} onClose={() => this.setState({ open: false })} maxWidth="sm" fullWidth PaperProps={{
          style: { borderRadius: 16, width: "100%" }
        }} >
          <DialogTitle sx={styles.dialogTitle}>
            New availability
            <IconButton
              data-test-id="close-icon"
              aria-label="close"
              onClick={() => this.setState({ open: false })}
              sx={{ position: 'absolute', right: 8, top: 8, }}
            >
              <CloseIcon sx={styles.closeIcon} />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={styles.dialogContent} >
            {this.renderCalenderView()}
            {this.renderTimeSlots()}
            <View
              data-test-id="dateContainer"
              style={styles.dateContainer}
            >
              <View style={styles.subContainer2}>
                <TouchableOpacity
                  data-test-id="addTimeslotbtn"
                  onPress={() => this.handleAddTimeSlot()}
                >
                  <View style={styles.flexCenter}>
                    <AddCircleOutlineIcon style={styles.editIcon} />
                    <View style={{ marginLeft: 25 }}>
                      <Typography
                        style={styles.timeslotTypo}
                      >
                        Add another timeslot
                      </Typography>
                    </View>
                  </View>
                </TouchableOpacity>
              </View>
            </View>
            {this.renderTimezoneOverlay()}
          </DialogContent>
        </Dialog>

        <Dialog open={this.state.isSetDetailsOpen} onClose={() => this.setState({ isSetDetailsOpen: false })} maxWidth="sm" fullWidth PaperProps={{
          style: { borderRadius: 16, width: "100%" }
        }} >
          <DialogTitle sx={styles.dialogTitle}>
            <TouchableOpacity
              data-test-id="backBtn"
              onPress={() => {
                this.setState({ isSetDetailsOpen: false, open: true });
              }}
            >
              <img src={leftArrow} style={styles.leftArrow} />
            </TouchableOpacity>
            New availability
            <IconButton
              aria-label="close"
              data-test-id="cancelIcon"
              onClick={() => this.setState({ isSetDetailsOpen: false })}
              sx={{ position: 'absolute', right: 8, top: 8, }}
            >
              <CloseIcon sx={styles.closeIcon} />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={styles.dialogContent} >
            <View style={{ height: "inherit" }}>
              <View style={styles.detailsContainer}>
                <Typography style={styles.detailsText} >
                  DETAILS
                </Typography>
              </View>
              {this.renderSelectAirportView()}
              {this.renderSelectTestsView()}
              <View  style={styles.borderBottom}>
                <Box data-test-id="calenderopenbtn" >
                  <View style={styles.subContainerNotify}>
                    <View style={styles.flexCenter}>
                      <NotificationsOutlinedIcon style={styles.icons} />
                      <View style={{ marginLeft: 20 }}>
                        <Typography
                          style={styles.notifyAll}
                        >
                          Notify all on waitlist
                        </Typography>
                      </View>
                    </View>
                    <Switch
                      data-test-id="shouldNotifyAlluser"
                      checked={this.state.shouldNotifyAlluser}
                      onChange={() => this.setState({ shouldNotifyAlluser: !this.state.shouldNotifyAlluser })}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </View>
                </Box>
              </View>
              {this.renderSelectNotifyPeoples()}
            </View>
            <View style={styles.subContainer2}>
                <StyledButtonContainer>
                  <CustomButton
                    data-test-id="cancel-details"
                    variant="outlined"
                    className="cancelButton"
                    onClick={() => {
                      this.setState({ isSetDetailsOpen: false, open: true});
                    }}>
                      Cancel
                  </CustomButton>
                </StyledButtonContainer>
              
                <StyledButtonContainer>
                  <CustomButton
                    data-test-id="save-availability"
                    variant="contained"
                    disabled={this.shouldEnableCreateAvailablityBtn()}
                    className={ this.shouldEnableCreateAvailablityBtn() ? "saveButton":"setDetailsButton"}
                    color="primary"
                    onClick={() => {
                      this.setState({ isSetDetailsOpen: true, open: false });
                    }}>Save Availability</CustomButton>
                </StyledButtonContainer>
            </View>
          </DialogContent>
        </Dialog>
        </>
    );
  }
}

const StyledButtonContainer = styled(Box)({
  "& .saveButton": {
      height: "50px",
      fontSize: "16px",
      padding: "0px",
      fontWeight: 700,
      fontFamily: "Montserrat",
      textTransform: "none",
      opacity: "30%",
      borderRadius: "20px",
      color: "#ffffff",
      background: "#0074BF",
    },
  "& .css-riz2hf-MuiButtonBase-root-MuiButton-root.Mui-disabled":{
      opacity: "30%",
      background: "#0074BF",
      color:"#fff"
    },
  "& .setDetailsButton": {
      height: "50px",
      fontSize: "16px",
      padding: "0px",
      fontWeight: 700,
      fontFamily: "Montserrat",
      textTransform: "none",
      borderRadius: "20px",
      color: "#ffffff",
      background: "#0074BF",
    },
  "& .cancelButton": {
      height: "50px",
      fontSize: "16px",
      padding: "0px",
      fontWeight: 700,
      fontFamily: "Montserrat",
      textTransform: "none",
      borderRadius: "20px",
    },
})
const StyledContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  // height: "fit-content",
  alignItems: "center",
  // maxWidth: "clamp(400px, 50vw, 860px)",
  background: "#ffffff",
  borderRadius: "20px",
  marginTop: "40px",
  // width: "100%",
  // padding: "20px",

  "& .form": {
    width: "100%",
    maxWidth: "80%",
    gap: "15px",
    flexDirection: "column",
    // minWidth:"400px",
    display: "flex",

    "& .fieldContainer": {
      flexDirection: "column",
      gap: "5px",
      display: "flex",
      "& .fieldLabel": {
        color: "#334155",
        fontSize: "16px",
        fontFamily: "Montserrat",
        fontWeight: 600,
      },
    },

    "& .saveButton": {
      opacity: "30%",
      padding: "0px",
      height: "50px",
      fontSize: "16px",
      fontWeight: 700,
      fontFamily: "Montserrat",
      textTransform: "none",
      borderRadius: "20px",
      color: "#ffffff",
      background: "#0074BF",
    },
  },

  "@media (max-width: 900px)": {
    maxWidth: "clamp(300px, 90vw, 800px)",
  },
  "@media (max-width: 600px)": {
    // maxWidth: "100%",
    // height:"100%",
    "& .form": {
      maxWidth: "100%",
      minWidth:"290px",
    }
  }

});
const StyledContainerBigCal = styled(Box)({
  // height: "fit-content",
  // maxWidth: "clamp(400px, 50vw, 860px)",
  // marginTop: "40px",
  // width: "100%",
  alignItems: "center",
  flexDirection: "column",
  background: "#ffffff",
  display: "flex",
  borderRadius: "20px",
  padding: "30px 30px 30px 30px",

  "& .form": {
    // minWidth:"400px",
    maxWidth: "80%",
    gap: "15px",
    flexDirection: "column",
    width: "100%",
    display: "flex",

    "& .fieldContainer": {
      flexDirection: "column",
      display: "flex",
      gap: "5px",
      "& .fieldLabel": {
        color: "#334155",
        fontSize: "16px",
        fontWeight: 600,
        fontFamily: "Montserrat",
      },
    },

    "& .saveButton": {
      height: "50px",
      fontSize: "16px",
      padding: "0px",
      fontWeight: 700,
      fontFamily: "Montserrat",
      textTransform: "none",
      opacity: "30%",
      borderRadius: "20px",
      color: "#ffffff",
      background: "#0074BF",
    },
  },

  "@media (max-width: 900px)": {
    maxWidth: "clamp(300px, 90vw, 800px)",
  },
  "@media (max-width: 600px)": {
    // maxWidth: "100%",
    // height:"100%",
    "& .form": {
      maxWidth: "100%",
      minWidth:"290px",
    }
  }

});
const calendarStyles = {
   '--react-calendar-weekdays-decoration': 'none',
   color: '#64748B',
   overflow: 'hidden',
   borderRadius: '20px', 
  border: 'none',
 
};


const CustomBox = styled(Box)({
  // position:"fixed",
  top: "100%",
  left: 300,
  zIndex: 1000,
  background: "#fff",
  // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  borderRadius: "8px",
  padding: "10px"
})
const CustomButton = styled(Button)({
  textTransform: "capitalize",
  fontWeight: 700,
  fontSize: 16,
  width: 258,
  borderRadius: 20
})
const CustomTextField = styled(TextField)({
  border: "none",
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none"
  },
  "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
    padding: "0px 0px 0px 25px"
  }
})
const CustomSearchField = styled(TextField)({
  border: "none",
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none"
  },
  "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
    padding:0
  }
})
const webStyle = {
  headerContainer: {
    display: "flex",
    height: "60px",
    padding: "10px 50px 10px 50px",
    alignItems: "center",
    background: "#ffffff",
    justifyContent:"space-between"
  },
  headerText: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "22px",
    color: "#0074BF",
  },
  text: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "15px",
    color: "#94A3B8",
    marginBottom:'5px'
  },
  text1: {
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "13px",
    color: "#94A3B8",
    marginBottom:'80px',
    textAlign:'center'
  },
  headerText1: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "14px",
    color: "#00AFEF",
    border:'2px solid #00AFEF',
    borderRadius:'20px',
    padding:'6px 20px 6px 20px',
    display:'flex',
    gap:'5px'
  },
  headerText2: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "14px",
    color: "white",
    border:'2px solid #00AFEF',
    borderRadius:'20px',
    padding:'6px 20px 6px 20px',
    display:'flex',
    backgroundColor:'#00AFEF',
    gap:'5px'
  },
};
const styles = StyleSheet.create({
  dateContainer :{
    width: "100%",
    borderBottomWidth: 1,
    borderBottomColor: "#E2E8F0",
    height: 64,
  },
  settings:{
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
    marginLeft:10
  },
  padding:{
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
  checked:{
    width:20,
    height:20
  },
  selectAllTest:{
    paddingVertical: 10,
    paddingHorizontal: 12,
    flexDirection: "row",
    alignItems: "center",
  },
  subCate:{
    marginLeft: 22,
    fontSize: 16,
    fontWeight: "400",
    color: "#0F172A",
  },
  editIcon:{color:"#0074BF"},
  eastIcon: {color:"#5274B5"},
  subContainer:{
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginHorizontal: 30,
  },
  subContainer2:{
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginVertical: 20,
    marginHorizontal: 30,
  },
  subContainerNotify:{
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginVertical: 13,
    marginRight:18,
    marginLeft:30
  },
  timePickerContainer:{ 
    flexDirection: "row", 
    marginTop: 20, 
    alignItems: "center", 
    width: "255px" ,
  },
  flexContainer:{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icons:{color:"#94A3B8"},
  arrowStyle:{
    height: 12,
    width: 12,
    resizeMode: "contain",
    tintColor: "#94A3B8",
  },
  iconColor:{ color: "#5274B5" },
  deleteIcon:{ color: "#F87171" },
  timeslotTypo:{
    fontSize: 16,
    color: "#0074BF",
    fontWeight: "500",
  },
  flexCenter:{ flexDirection: "row", alignItems: "center" },
  endTimeContainer:{ flexDirection: "row", marginTop: 20, width: "200px" },
  notifyAll:{
    fontSize: 16,
    color: "#0F172A",
    fontWeight: "500",
  },
  dialogTitle:{
    borderBottomWidth: 1,
    borderBottomColor: "#E2E8F0",
    fontSize: 22,
    fontWeight: "700",
    textAlign: 'left',
    color: "#001C55",
    fontFamily: "Montserrat",
    display: "flex",
    alignItems: "center"
  },
  closeIcon:{ width: "18px", height: "18px", color: "#0074BF", marginRight: "12px" },
  borderBottom:{ 
    borderBottomWidth: 1,
    borderBottomColor: "#E2E8F0",
  },
  dialogContent:{ marginTop: 0, height: "inherit", paddingTop:10, paddingLeft:0, paddingRight:0, },
  leftArrow:{ marginRight: 10, height: '21px', width: '24px', color: "#0074BF" },
  detailsContainer:{
    height: 40,
    width: "100%",
    backgroundColor: "#5274B51A",
    justifyContent: "center",
  },
  detailsText:{
    fontSize: 14,
    fontWeight: "700",
    color: "#5274B5",
    lineHeight: 22,
    marginLeft: 30,
  },
  calenderContainer:{ 
    position: "relative" ,
    borderBottomWidth: 1,
    borderBottomColor: "#E2E8F0",
    height: 64, 
  },
  searchContainer:{ flexDirection: "row",
    alignItems: "center",
    paddingVertical: 5,
    marginBottom: 10,
    paddingLeft: 16,
  },
  searchPlaceholder:{
    marginLeft: 20,
    width: "90%",
    fontSize: 16,
    fontWeight: "400"
  },
  allCheck: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 10,
    marginHorizontal:20
  },
  memberName:{ 
    marginLeft: 20,
    fontSize: 16,
    fontWeight: "400",
    color: "#0F172A",
  },
  imageStyle:{height:20, width:20},
  imageProfileStyle: {
    width: 25,
    height: 25,
    borderRadius: 20,
    marginLeft:20
  },
  initialsContainer: {
    backgroundColor: "#5274B51A",
    justifyContent: "center",
    alignItems: "center",
    color:"#0074BF",
  },
  initialsText: {
    color: "#0074BF",
    fontSize: 12,
    fontWeight: "bold",
  },
  memberProfile:{height:20, width:20, borderRadius:50},
  selectText:{ 
    marginLeft: 20,
    fontSize: 16,
    fontWeight: "400",
    color: "#0F172A",
  },
  selectedZone:{
    marginLeft: 80,
    fontSize: 16,
    color: "#0F172A",
    fontWeight: "500",
    lineHeight: 24,
    textAlign: "justify",
  },
  selectAirport:{
    paddingVertical: 10,
    paddingHorizontal: 32,
    borderBottomWidth: 1,
    borderBottomColor: "#E2E8F0",
  },
  selectAllAirport:{
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 10,
  },
  editText:{
    marginLeft: 22,
    fontSize: 16,
    fontWeight: "500",
    color: "#0074BF",
  },
  editAirport:{
    marginLeft: 18,
    fontSize: 16,
    fontWeight: "500",
    color: "#0074BF",
  },
  allCheckImage:{
    height: 20,
    width: 20,
    resizeMode: "contain",
  },
  airportText:{
    marginLeft: 22,
    fontSize: 16,
    fontWeight: "400",
    color: "#0F172A"
  },
  singleTest:{
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 10,
    marginLeft:12
  }
})
// Customizable Area End
