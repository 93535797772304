import PropTypes from "prop-types";
import React, { Component } from "react";

import { AppBar, Box, Typography, styled } from "@mui/material";
import {
  appLogo,
  calender,
  settings,
  notifications,
  mail,
  listIcon,
} from "../../../blocks/navigationmenu/src/assets";
import {darkCal} from "./assets";
type MyProps = {
  navigation: any;
  testID: string;
  isChecked: boolean;
  onChangeValue?: (value: boolean) => void;
 
  children?: React.ReactNode; // Add this line

};
type MyState = { isChecked: boolean; onChangeValue?: (value: boolean) => void };

export default class NavigationCalendar extends Component<MyProps, MyState> {
  static propTypes = {
    testID: PropTypes.string,
    isChecked: PropTypes.bool.isRequired,
    onChangeValue: PropTypes.func.isRequired,
  };

  constructor(props: any) {
    super(props);

    this.state = {
      isChecked: this.props.isChecked,
    };
  }

  render() {


    return (
      <>
        <Container>
          <Box className="verticalSideBar">
            <Box className="OuterNavbar">
              <Box>
                <img src={appLogo} />
              </Box>
              <Box className="midNavContainer1">
                <Box className="navButtonContainer1">
                  <img src={calender} />
                  <Typography className="navButtonLabel1">Calendar</Typography>
                </Box>

                <Box className="navButtonContainer1">
                  <img src={listIcon} />
                  <Typography className="navButtonLabel1">Checklist</Typography>
                </Box>

                <Box className="navButtonContainer1">
                  <img src={notifications} />
                  <Typography className="navButtonLabel1">
                    Notifications
                  </Typography>
                </Box>

                <Box className="navButtonContainer1">
                  <img src={mail} />
                  <Typography className="navButtonLabel1">Messages</Typography>
                </Box>

                <Box className="navButtonContainer1">
                  <img src={settings} />
                  <Typography className="navButtonLabel1">Settings</Typography>
                </Box>
              </Box>
             
            </Box>
        
          </Box>
          <Box className="childContainer">{this.props.children}</Box>
          <AppBar sx={{ bottom:0,  zIndex:"1800",position:"fixed",top:"auto",}}>
            <Box className="horizontalNavBar">
              <Box className="navButtonContainer1">
                <img src={calender} />
                <Typography className="navButtonLabel1">Calendar</Typography>
              </Box>
              <Box className="navButtonContainer1">
                <img src={notifications} />
                <Typography className="navButtonLabel1">
                  Notifications
                </Typography>
              </Box>
              <Box className="navButtonContainer1">
                <img src={mail} />
                <Typography className="navButtonLabel1">Messages</Typography>
              </Box>
             
          
            </Box>
          </AppBar>
        </Container>
      </>
    );
   
}}
const webStyle = {
  outerContainer: {
    display: "flex",
    width: "100vw",
    height: "100vh",
    background: "#f1f5f9",
  },
  iconLabel: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "14.63px",
    letterSpacing: "-0.24px",
    textAlign: "center",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
    color:"#0074BF"
  },
};
const Container = styled(Box)({
  display: "flex",
  width: "100vw",
  height: "100vh",
  background: "#f1f5f9",
  "& .verticalSideBar": {
    display: "flex",
    positions: "fixed",
    "& .OuterNavbar": {
      padding: "20px",
      background: "#FFFFFF",
      width: "80px",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      borderRight: "1px solid #E2E8F0",
      display: "flex",
      "& .midNavContainer1": {
        gap: "40px",
        flexDirection: "inherit",
        display: "flex",
      },
    },
  },
  "& .horizontalNavBar": {
    zIndex: 20,
    padding: "10px",
    borderTop: "1px solid #E2E8F0",
    background: "white",
    alignItems: "center",
    justifyContent: "space-between",
    height: "60px",
    display: "none",
  },
  "& .childContainer": {
    overflowY: "auto",
    flexGrow: 1,
  },

  "& .navButtonContainer1": {
    alignItems: "center",
    flexDirection: "column",
    display: "flex",
    "& .navButtonLabel1": {
      color: "#94A3B8",
      fontFamily: "Montserrat",
      fontWeight: 700,
      fontSize: "12px",
    },
  },
  "@media (max-width: 900px)": {
    flexDirection: "column",
    "& .verticalSideBar": {
      display: "none",
      "& .OuterNavbar": {
        height: "70px",
        width: "100%",
        flexDirection: "row",
      },
    },
    "& .horizontalNavBar": {
      display: "flex",
    },
    "& .childContainer": {
      paddingBottom:'90px'
    },
  },
});
