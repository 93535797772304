import React from "react";

import {
  // Customizable Area Start
  Box,
  Typography,
  InputLabel,
  TextField,
  Grid,
  styled
  // Customizable Area End
} from "@mui/material";

import { loginLogo, loginImage } from "./../../email-account-login/src/assets";
import { leftArrow } from "./assets";

// Customizable Area Start
import * as Yup from "yup";
import { Formik } from "formik";
import ForgotPasswordController, { Props } from "./ForgotPasswordController.web";
import OtpInput from "react-otp-input";
import Loader from "../../../components/src/Loader.web";

// import For, { Props } from "./ForgotPasswordController.web";
// Customizable Area End

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ForgetPassContainer>
        <div className="Toastify"></div>
        <Grid container direction={'row'} style={{ width: "100%",height: "100%"}}>
          <Grid item xs={12} sm= {12} md={6} sx={{
            height: "100%",
          }}>
            <img src={loginLogo} style={{ width: "150px", height: "61px", padding: "32px 0px 0px 40px" }} />
            <Box className= "forgotPassContainer">
              <Box >
                {!this.state.isFormSubmitted && <Formik
                  initialValues={{
                    type: "Technician",
                    email: "",
                  }}
                  data-testId ="formiktestId"
                  validationSchema={Yup.object().shape({
                    email: Yup.string()
                      .email("Please enter a valid email")
                      .required("Email is required"),
                    type: Yup.string().required("Type is required"),

                  })}
                  validateOnBlur={true}
                  validateOnChange={true}
                  onSubmit={() => {
                    this.handleSendRecoveryLink();
                  }}
                >
                  {(props) => (
                    <form onSubmit={props.handleSubmit}>
                      <Box sx={webStyle.formWrapper}>
                      <Box className="forgotHeader">
                <span data-testId="backToprevious" onClick={() => this.navigateTopreviousScreen("Login")}>
                  <img src={leftArrow} style={{ margin: '7px 0px', height: '19px', width: '19px', cursor:"pointer" }} alt="left-arrow" />
                </span>
                <Typography variant="h6" sx={{
                  fontFamily: 'Montserrat, sans-serif',
                  fontWeight: 700, fontSize: "22px", color: "#001C55",
                }}
                >
                  Forgot your password?
                </Typography>
              </Box>
                  <Box sx={webStyle.inputWrapper}>
                    <Typography variant="h6" sx={{
                            margin: '10px 0px 32px 0px',
                            fontFamily: 'Montserrat, sans-serif',
                            fontWeight: 400, fontSize: "16px", color: "#0F172A"
                          }}>

                         No worries, we'll send you a 6-digit code to your email.
                </Typography>
                      <InputLabel style={webStyle.label} sx={{ color: '#334155' }}>Email</InputLabel>
                      <TextField
                        data-testId= "email"
                        name="email"
                        id="email"
                        value={props.values.email}
                        onChange={(event) => {
                          props.setFieldValue("email", event.target.value);
                          this.onValueChange("email", event.target.value);
                        }}
                        fullWidth={true}
                        variant="outlined"
                        type="email"
                        onBlur={props.handleBlur}
                        placeholder="Enter Email"
                        sx={{
                          "&.Mui-focused": {
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "1px solid #CBD5E1",
                              borderWidth: "1px",
                            },
                          },
                          backgroundColor: "#ffffff",
                          color: "#94A3B8",
                          mb: "3px",
                        }}

                        InputProps={{
                          style: {
                            border: "1px solid #CBD5E1",
                            borderRadius: '20px',
                            fontWeight: 400,
                            width: "100%"
                          }
                        }}
                      />
                      {this.getErrorMessage(
                        props.touched,
                        props.errors,
                        "email"
                      )}
                      </Box>

                      <button
                        type="submit"
                        className="btnStyle"
                        data-test-id="sendARecoveryLinkBtn"
                      > 
                      Send recovery code
                      </button>
                      <Typography variant="h6" align="center" sx={{
                        fontFamily: 'Montserrat, sans-serif',
                        fontWeight: 400, fontSize: "16px", color: "#0F172A",
                        display: "inline"
                      }}>
                        Back to
                        <Typography style={{
                          fontFamily: 'Montserrat, sans-serif', display: "inline",
                          fontWeight: 700, fontSize: "16px", color: "#0074BF", textDecoration: "none",
                          marginLeft: "7px",cursor:"pointer"
                        }} data-testId = "backToLoginId" onClick={() => this.navigateTopreviousScreen("Login")}>
                          Login
                        </Typography >
                      </Typography>

                      </Box>
                    </form>
                  )}
                </Formik>}
              </Box>
            </Box>
                {this.state.isFormSubmitted && <Box sx={webStyle.formWrapper}>
                <Box className="forgotHeader">
                <span data-testId="backBtn" onClick={this.goBackToForgetScreen}>
                  <img src={leftArrow} style={{ margin: '7px 0px', height: '19px', width: '19px',cursor:"pointer" }} alt="left-arrow" />
                </span>
                <Typography variant="h6" sx={{
                  fontFamily: 'Montserrat, sans-serif',
                  fontWeight: 700, fontSize: "22px", color: "#001C55",
                }}
                >
                  Check your inbox!
                </Typography>
              </Box>
                  <Box sx={webStyle.inputWrapper}>
                <Typography variant="h6" sx={{
                  margin: '15px 0px',
                  fontFamily: 'Montserrat, sans-serif',
                  fontWeight: 400, fontSize: "16px", color: "#0F172A"
                }}>
                  We have sent a 6-digit code to {this.state.email} Enter it to reset your password.
                </Typography>

                  </Box>

                  <OtpInput
                        value={this.state.emailOtp}
                        data-testId = "setOtpTextId"
                        onChange={this.forgetOtp}
                        numInputs={6}
                        renderInput={(props) => <input {...props} className={this.state.forgotEmailOtpError ? "resetOtpInputError" : "resetOtpInput"} />}
                    />
                     {this.state.forgotEmailOtpError && (
                        <div className="forgotMessageIconContainer">
                            <span className="forgotMessageText">
                                The entered OTP is invalid.
                            </span>
                        </div>
                    )}
                  {!this.state.isLoading ? 
                  <>
                   <button
                   type="submit"
                   className = "recoveryBtn"
                   data-testId ="sendARecoveryLinkAgainBtn"
                   onClick={this.resendForgotOtpApi}
                 >
                   Resend code
                 </button>
                  <Typography variant="h6" align="center" sx={{
                    fontFamily: 'Montserrat, sans-serif',
                    fontWeight: 400, fontSize: "16px", color: "#0F172A",
                    display: "inline"
                  }}>Back to
                    <Typography style={{
                      fontFamily: 'Montserrat, sans-serif',
                      display: "inline",
                      fontWeight: 700, fontSize: "16px",
                      color: "#0074BF", textDecoration: "none",
                      marginLeft: "7px", cursor:"pointer"
                    }} data-testId = "backToLogin" onClick={() => this.navigateTopreviousScreen("Login")}>
                      Login
                    </Typography >
                  </Typography>
                  </>
                  :
                  <Loader loading={this.state.isLoading}/>
                  }
                 
                </Box>}
         
            
          </Grid>
          <Grid item xs={12} sm= {12} md={6} padding={0} className="forgetOtpDisplay">
            <img src={loginImage} style={{ height: "100%", width: "100%" }} />
          </Grid>
        </Grid>
      </ForgetPassContainer>
      // Customizable Area End
    );
  }
}

const webStyle = {
  inputWrapper: {
    mb: 1,
    maxWidth: "360px",
    position: "relative",
    width: "100%",
    "@media(min-width: 360px) and (max-width: 414px)": {
      width: "300px",
    },
  },
  
  btnStyle: {
    height: "50px",
    backgroundColor: "#0074BF",
    color: "white",
    cursor: "pointer",
    fontWeight: 700,
    textTransform: "none",
    width: "360px",
    marginTop: "25px",
    marginBottom: "50px",
    border: "none",
    borderRadius: "20px",
  },
  label: {
    marginTop: "17px",
    color: "#334155",
    fontSize: "16px",
    fontWeight: 600,
  },
  formWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },
};


const ForgetPassContainer = styled(Box)({
  height: "auto",
  "& .forgetOtpDisplay": {
    "@media (max-width: 899px)": {
      display: "none"
    }
  },
  "& .forgotHeader": {
    marginTop: "90px",
    marginLeft: "-144px",
    display: 'flex',
    gap: "35px",
    alignItems: 'center',
    "@media(min-width: 360px) and (max-width: 414px)": {
      marginTop: "40px",
      marginLeft: "-30px",
      gap: "18px",
    },
    "@media(min-width: 415px) and (max-width: 600px)": {
      marginLeft: "-128px",
      gap: "18px",
    },
    "@media(min-width: 900px) and (max-width: 1180px)": {
      marginLeft: "-128px",
      gap: "18px",
    },
  },
  "& .resetOtpInput": {
    height: "50px",
    borderRadius: "20px",
    border: "1px solid #CBD5E1",
    background: "#FFF",
    width: "48px !important",
    margin: "24px 5px 0px 5px",
    "&:focus": {
      borderColor: "#0074BF !important",
      outline: "none"
    },
    "@media (max-width: 414px)": {
      width: "38px!important",
      height: "40px",
      borderRadius: "15px",
    }
  },
  "& .resetOtpInputError": {
    borderRadius: "20px",
    border: "1px solid #DC2626",
    background: "#FFF",
    height: "50px",
    width: "48px !important",
    margin: "24px 5px 0px 5px",
    outline: "none",
    "@media (max-width: 414px)": {
      width: "38px!important",
      height: "40px",
      borderRadius: "15px",
    }
  },
  "& .btnStyle": {
    height: "50px",
    backgroundColor: "#0074BF",
    color: "white",
    cursor: "pointer",
    fontWeight: 700,
    textTransform: "none",
    width: "360px",
    marginTop: "25px",
    marginBottom: "50px",
    border: "none",
    borderRadius: "20px",
    "@media (max-width: 414px)": {
      width: "300px",
    }
  },
  "& .recoveryBtn": {
    border: "2px solid #0074BF",
    backgroundColor: "#ffffff",
    marginTop: "25px",
    marginBottom: "20px",
    padding: '10px 20px',
    color: "#0074BF",
    borderRadius: '20px',
    cursor: "pointer",
    fontWeight: 700,
    fontSize: "16px",
    height: "50px",
    width: "360px",
    textTransform: "none",
    "@media (max-width: 414px)": {
      width: "300px",
    }
  },
  "& .forgotMessageIconContainer": {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px"
  },
  "& .forgotMessageText": {
    color: "#DC2626",
    fontFamily: 'Montserrat, sans-serif',
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "30px",
  },
})