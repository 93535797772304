export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");

export const loginLogo = require("../assets/login-logo.png");
export const loginImage = require("../assets/group_Signup_card.png");


export const warning = require("../assets/warning.png");
export const leftArrow = require("../assets/left-arrow.png");
export const pdfIcon = require("../assets/pdf-icon.png");
export const uploadIcon = require("../assets/upload.png");
export const bgImage = require("../assets/bg-image.png");
export const binIcon = require("../assets/bin.svg");
export const logOutIcon = require("../assets/logout.svg");
export const flightIcon = require("../assets/flight_takeoff.svg");
export const airPlane = require("../assets/airplane.svg");
export const dpeImage = require("../assets/dpe.png");
export const mailIcon = require("../assets/mail.png")
