Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
  exports.getAirportUrl =
  "/bx_block_automatedprioritisation/service_sub_categories_for_category/Airport";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";

exports.errorPass="Password does not meet requirements";
exports.getDesignationUrl='/bx_block_categories/designation_instructions/designation_instructions_for_dpe';
exports.postDesignationUrl= 'bx_block_categories/designation_instructions/create_multiple'
exports.gradeOfCertificateEndPoint = "bx_block_automatedprioritisation/service_sub_categories_for_category/Grade of certificate";
exports.categoryEndPoint = "bx_block_automatedprioritisation/service_sub_categories_for_category/Category";
exports.airPortClassEndPoint = "bx_block_automatedprioritisation/service_sub_categories_for_category/Class";
exports.aircraftTypeEndPoint = "bx_block_automatedprioritisation/service_sub_categories_for_category/Aircraft";
exports.postAirCraftEndPoint = "bx_block_automatedprioritisation/add_subcategory_by_dpe";
exports.instructionsEndPoint = "bx_block_categories/designation_instructions/"
exports.putMethod = "PUT";
exports.welcomeText = "Your account has been successfully activated. You can start booking your tests right away!";
exports.accountStatusText = "Please enter the activation code we sent to your email. This step helps us verify your account and ensures your security.";
exports.resendOtpEndPoint = "account_block/accounts/resend_otp_code";
exports.verifyOtpEndPoint ="account_block/accounts/validate_otp_code";
// Customizable Area End
