export const plusIcon = require("../assets/plusIcon.png");
export const calendar1 = require("../assets/calendar1.png");
export const left = require("../assets/left.png");
export const right = require("../assets/right.png");
export const calendar2 = require("../assets/calender2.png");
export const est = require("../assets/est.png");
export const day = require("../assets/day.png");
export const day3 = require("../assets/day3.png");
export const month = require("../assets/month.png");
export const schedule = require("../assets/schedule.png");
export const week = require("../assets/week.png");
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const checkedBox = require("../assets/checked.png");
export const unCheckedBox = require("../assets/unchecked.png");
export const darkCal = require("../assets/darkCal.png").default;
