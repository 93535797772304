import React from 'react';
import { Box } from '@mui/material';
import { uploadIcon } from './../assets';

interface UploadButtonProps {
  onFileSelect: (files: FileList | null) => void;
}

class UploadPdfButton extends React.Component<UploadButtonProps> {
  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onFileSelect(event.target.files);
  };

  render() {
    return (
      <label htmlFor="upload-pdf">
        <Box sx={styles.uploadButton} component="span">
          <img style={{marginRight:'5px'}} src={uploadIcon} width={'16px'} height={'16px'}/>
          Upload PDF
        </Box>
        <input
          id="upload-pdf"
          type="file"
          accept=".pdf"
          multiple
          onChange={this.handleFileChange}
          style={{ display: 'none' }}
        />
      </label>
    );
  }
}
export default UploadPdfButton;
const styles = {
  uploadButton: {
    fontFamily:'Montserrat, sans-serif',
    padding: '6px 20px',
    borderRadius: '20px',
    color: '#0EA5E9',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 700,
    border: '1px solid #0074BF',
    display: 'flex',
    alignItems: 'center',
    width:'110px',
    "@media (max-width: 414px)": {
      width: "250px",
      height: "35px",
      justifyContent: "center"
    }
  },
}