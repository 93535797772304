import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { FormikTouched, FormikErrors } from "formik";
import React from "react";
import { toast } from "react-toastify";

interface ErrorToken {
  token: string;
}
interface ErrorToken {
  otp: string;
}

interface ErrorResponse {
  errors: ErrorToken[];
}

interface ForgotPasswoedResponse {
  message: string,
  token: string
}

interface MessageResponse {
  otp: string;
}

interface VerifyOtpResponse {
  messages: MessageResponse[];
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  isFormSubmitted: boolean;
  emailOtp: string;
  resendOtp: boolean;
  forgotEmailOtpError: boolean;
  showResendBtn: boolean;
  isLoading: boolean;
  // Customizable Area End
}

// Customizable Area Start
export interface Dropdown {
  value: string;
  label: string;
}
// Customizable Area End

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class ForgotPasswordController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  sendLinkApiCallId: string = "";
  otpVerifyApiCallId: string = "";
  resendForgotOtpApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationPropsMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      password: "",
      email: "",
      isFormSubmitted: false,
      emailOtp: "",
      resendOtp: false,
      forgotEmailOtpError: false,
      showResendBtn: false,
      isLoading: false
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.responseSucessCell(apiRequestCallId, responseJson)
    }
    else if (responseJson && responseJson.errors) {
        this.responseFailureCell(apiRequestCallId, responseJson)
    }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getErrorMessage = (
    touched: FormikTouched<object>,
    errors: FormikErrors<object>,
    value: string
  ) => {
    return (
      touched[value as keyof object] &&
      errors[value as keyof object] && (
        <div
          style={{
            fontSize: "12px",
            fontWeight: 300,
            color: "#f94b4b",
          }}
        >
          {errors[value as keyof object]}
        </div>
      )
    );
  };

  onValueChange = (name: string, value: string) => {
    this.setState({ ...this.state, [name]: value });
  };

  forgotPasswordApiCall = async (valueData: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    apiType?: string;
    type?: string;
}) => {
    const token = await (localStorage.getItem('accessToken'))
    let { contentType, method, endPoint, body, apiType } = valueData;
    let header = {
        "Content-Type": contentType,
        Token: token
    };
    let forgotPasswordRequestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    forgotPasswordRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
    );
    forgotPasswordRequestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
    );
    body &&
    forgotPasswordRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            apiType === "" ? JSON.stringify(body) : body
        );
        forgotPasswordRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    runEngine.sendMessage(forgotPasswordRequestMessage.id, forgotPasswordRequestMessage);
    return forgotPasswordRequestMessage.messageId;
};

responseSucessCell = async (apiRequestCallId: string, responseJson: ForgotPasswoedResponse & VerifyOtpResponse) => {
    if (apiRequestCallId === this.sendLinkApiCallId) {
        this.forgotPasswordSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.otpVerifyApiCallId) {
        this.otpVerifySucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.resendForgotOtpApiCallId) {
      this.resendForgotSucessCallBack(responseJson);
  }
};

responseFailureCell = async (apiRequestCallId: string, responseJson: ErrorResponse) => {
    if (apiRequestCallId === this.sendLinkApiCallId) {
        this.forgotPasswordFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.otpVerifyApiCallId) {
        this.otpVerifyFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.resendForgotOtpApiCallId) {
      this.resendForgotFailureCallBack(responseJson);
  }
};

handleSendRecoveryLink = async () => {
    const body = {
      data: {
        type: "otps",
        attributes: {
          email: this.state.email
        }
      }
    }
    
    this.sendLinkApiCallId = await this.forgotPasswordApiCall({
        contentType: configJSON.contentTypeApiForgotPassword,
        method: configJSON.httpPostMethod,
        endPoint: "bx_block_forgot_password/forgot_password/forgot_password",
        body: JSON.stringify(body),
    });
};

forgotPasswordSucessCallBack = (response: ForgotPasswoedResponse) => {
    if(response){
      localStorage.setItem("accessToken", response.token);
      toast.success(response.message)
        this.setState({ emailOtp: "",forgotEmailOtpError: false, isFormSubmitted: true ,resendOtp:false})
    }
}

forgotPasswordFailureCallBack = (response: ErrorResponse) => {
    toast.error(response.errors[0].token)
};

otpVerifyApi = async () => {
  this.setState({isLoading: true})
    let token = localStorage.getItem('accessToken');
    const body = {
        token: token,
        otp_code: this.state.emailOtp
    }

    this.otpVerifyApiCallId = await this.forgotPasswordApiCall({
        contentType: configJSON.contentTypeApiForgotPassword,
        method: configJSON.httpPostMethod,
        endPoint: configJSON.forgotOtpVerifyEndPoint,
        body: JSON.stringify(body),
    });
};

otpVerifySucessCallBack = (response: VerifyOtpResponse) => {
    if (response) {
        this.setState({  forgotEmailOtpError: false, resendOtp: false,isLoading: false })
      window.location.href = `/reset-password?emailid=${encodeURIComponent(this.state.email)}`;
    }
}

otpVerifyFailureCallBack = (response: ErrorResponse) => {
    if (response.errors[0].token) {
        toast.error(response.errors[0].token)
        this.setState({isLoading: false})
    } else {
        this.setState({ forgotEmailOtpError: true, isLoading:false })
    }
};

resendForgotOtpApi = async () => {
  this.setState({isLoading: true})
  const body = {
    data: {
      type: "otps",
      attributes: {
        email: this.state.email
      }
    }
  }

  this.resendForgotOtpApiCallId = await this.forgotPasswordApiCall({
      contentType: configJSON.contentTypeApiForgotPassword,
      method: configJSON.httpPostMethod,
      endPoint: "bx_block_forgot_password/forgot_password/forgot_password",
      body: JSON.stringify(body),
  });
};

resendForgotSucessCallBack = (response: VerifyOtpResponse) => {
  if (response) {
      this.setState({  resendOtp: true,forgotEmailOtpError: false,isLoading: false,emailOtp:"" })
  }
}

resendForgotFailureCallBack = (response: ErrorResponse) => {
  if (response.errors[0].token) {
      toast.error(response.errors[0].token)
      this.setState({isLoading:false, emailOtp:"" , forgotEmailOtpError: false})
  }
};

  forgetOtp = (value: string) => {
    if (value.length == 6) {
      this.setState({
        emailOtp: value,
        forgotEmailOtpError: false
      },() => this.otpVerifyApi());
    } else {
      this.setState({
        emailOtp: value,
        forgotEmailOtpError: false
      });
    }
  };

  goBackToForgetScreen = () =>{
    this.setState({isFormSubmitted: false})
  }

  navigateTopreviousScreen = (pathName: string) => {
    const msgName: Message = new Message(getName(MessageEnum.NavigationMessage));
    msgName.addData(getName(MessageEnum.NavigationTargetMessage), pathName);
    msgName.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    msgName.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msgName);
  };

  // Customizable Area End
}
