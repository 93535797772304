import React from "react";
import {
  Box,
} from "@mui/material";
import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress';
import { Theme } from "@mui/material/styles";

interface myProps {
  loading: boolean;
}

export default function Loader(props: myProps) {
  return (<>
  {
    props.loading ?
    <Box style = {{position: "relative",
      display: "inline-flex",
      marginTop: "25px",
      marginBottom: "20px"}}>
                <CircularProgress
                    variant="determinate"
                    value={100}
                    size={40}
                    thickness={4}
                    sx={(theme: Theme) => ({
                        color: theme.palette.mode === "dark"
                            ? theme.palette.grey[800]
                            : theme.palette.grey[200],
                    })}
                />
                <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    size={40}
                    thickness={4}
                    sx={() => ({
                        position: "absolute",
                        left: 0,
                        color: "#0074BF",
                        animationDuration: "550ms",
                        [`& .${circularProgressClasses.circle}`]: {
                            strokeLinecap: "round",
                        },
                    })}
                />
            </Box>
            : ""
                  }</>
  );
}

const webStyles = {
  root: {
    position: "absolute" as const,
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgb(0, 0, 0, .4)",
    zIndex: 1,
  },
  circularContainer: {
    position: "absolute" as const,
    left: "50%",
    top: "40%",
    transform: "translate(-50%, -50%)",
  },
};