import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
// Customizable Area Start
import { toast } from "react-toastify";
import React from "react";

interface ResetPassword {
        status: string;
        message: string;
}

interface ErrorToken {
    token: string;
  }
  
  interface ErrorResponse {
    errors: ErrorToken[];
  }

  interface ErrorSamePassResponse {
    error: string[];
  }
  
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    password: string;
    confirmPassword: string;
    showPassword: boolean;
    showConfirmPassword: boolean;
    isFormSubmitted: boolean;
    setEmailId: string | null;
    // Customizable Area End
    errors: {
        [key: string]: string;
    };
    passwordStrength: number;

}

// Customizable Area Start

// Customizable Area End

interface SS {
    // Customizable Area Start
    id: number;
    // Customizable Area End
}
export interface PasswordRequirement {
    text: string;
    regex?: RegExp;
    test?: (value: string) => boolean;
}
export default class ResetPasswordController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    loginApiCallId: string = "";
    resetPasswordApiCallId :string = "";
    // Customizable Area End
    passwordRequirements: PasswordRequirement[];

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            // Customizable Area End
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            password: "",
            confirmPassword: "",
            showPassword: false,
            showConfirmPassword: false,

            isFormSubmitted: false,
            errors: {},
            passwordStrength: 0,
            setEmailId: null

            // Customizable Area End
        };

        this.passwordRequirements = [
            { text: "At least one capital letter", regex: /[A-Z]/ },
            { text: "At least one lowercase letter", regex: /[a-z]/ },
            { text: "At least one number", regex: /\d/ },
            { text: "At least one special character", regex: /[!@#$%^&*(),.?":{}|<>]/ },
            { text: "Minimum character length is 8 characters", test: (value: string) => value.length >= 8 }
        ];
        // Customizable Area Start
        // Customizable Area End
    }

    // Customivalzable Area Start
    // Customizable Area End


    handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const errors = this.validateOnSubmit()
        if (Object.values(errors).some(error => error !== '')) {
            console.log("errors")


        } else {
            console.log("submit")

        }

    }

    hasErrors = () => {
        return Object.values(this.state.errors).some((error) => error !== "");
    };

    handleInputChange = (field: keyof S, value: string | boolean) => {
        this.setState((prevState) => {
            const newState = { ...prevState, [field]: value };
            if (field === 'password') {
                this.checkPasswordStrength(value as string);
            }
            this.validateField(field, value);
            return newState;
        });
    }
    
    checkPasswordStrength = (password: string) => {
        let strength = 0;
        if (password.length >= 8) strength++;
        if (password.match(/[a-z]+/)) strength++;
        if (password.match(/[A-Z]+/)) strength++;
        if (password.match(/\d+/)) strength++;
        if (password.match(/[$@#&!]+/)) strength++;
        this.setState({ passwordStrength: strength });
    }

    validateField = (field: keyof S, value: string | boolean) => {
        let errors = { ...this.state.errors };
        switch (field) {
            case 'password':
                errors.password = (value as string).length >= 8 ? '' : 'Password must be at least 8 characters';
                break;
            case 'confirmPassword':
                if (value === "") {
                    errors.confirmPassword = 'Confirm Password is required';
                } else if (value !== this.state.password) {
                    errors.confirmPassword = 'Passwords do not match';
                } else {
                    errors.confirmPassword = '';
                }
                break;

        }

        this.setState({ errors });
    }
    validateOnSubmit = () => {
        let errors = { ...this.state.errors };
        console.log("im here in validator")
        if (this.state.password && this.state.password.length < 8) {
            errors.password = configJSON.passwordError;
        } else {
            errors.password = '';
        }

        if (!this.state.confirmPassword) {
            errors.confirmPassword = 'Confirm Password is required';
        } else if (this.state.confirmPassword !== this.state.password) {
            errors.confirmPassword = 'Passwords do not match';
        } else {
            errors.confirmPassword = '';
        }
        this.setState({ errors });
        return errors
    }
    getPasswordRequirementIconStyle = (requirement: PasswordRequirement, password: string) => {
        const webStyle = {
            checkIcon: {
                color: '#34D399',
                fontSize: '16px',
                marginRight: '8px',
            },
            uncheckIcon: {
                color: '#9E9E9E',
                fontSize: '16px',
                marginRight: '8px',
            },
        };

        let isMet = true;

        if (requirement.regex) {
            isMet = requirement.regex.test(password);
        } else if (requirement.test) {
            isMet = requirement.test(password);
        }

        return isMet ? webStyle.checkIcon : webStyle.uncheckIcon;
    };

    togglePasswordVisibility = (field: 'showPassword' | 'showConfirmPassword') => {

        this.setState((prevState: any) => ({
            ...prevState,
            [field]: !prevState[field]
        }));
    }
    // Customizable Area Start
    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
    
          const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
          if (responseJson && !responseJson.errors) {
            this.responseSucessCell(apiRequestCallId, responseJson)
        }
        else if (responseJson && responseJson.errors) {
            this.responseFailureCell(apiRequestCallId, responseJson)
        }
        }
      }

    async componentDidMount() {
        const searchParams = new URLSearchParams(window.location.search);
        this.setState({ setEmailId: searchParams.get('emailid') })
    }

    resetPasswordApiCall = async (valueData: {
        contentType?: string;
        method?: string;
        endPoint?: string;
        body?: {};
        apiType?: string;
        type?: string;
    }) => {
        const token = await (localStorage.getItem('accessToken'))
        let { contentType, method, endPoint, body, apiType } = valueData;
        let header = {
            "Content-Type": contentType,
            Token: token
        };
        let resetPasswordRequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        resetPasswordRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        resetPasswordRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        body &&
        resetPasswordRequestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                apiType === "" ? JSON.stringify(body) : body
            );
            resetPasswordRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(resetPasswordRequestMessage.id, resetPasswordRequestMessage);
        return resetPasswordRequestMessage.messageId;
    };

    responseSucessCell = async (apiRequestCallId: string, responseJson: ResetPassword ) => {
        if (apiRequestCallId === this.resetPasswordApiCallId) {
            this.resetPasswordSucessCallBack(responseJson);
        }
    };

    responseFailureCell = async (apiRequestCallId: string, responseJson: ErrorResponse & ErrorSamePassResponse) => {
        if (apiRequestCallId === this.resetPasswordApiCallId) {
            this.resetPasswordFailureCallBack(responseJson);
        } 
    };

    resetPasswordApi = async () => {
        const token = await (localStorage.getItem('accessToken'))
        const body = {
                token: token,
                password: this.state.password,
                password_confirmation: this.state.confirmPassword
        }

        this.resetPasswordApiCallId = await this.resetPasswordApiCall({
            contentType: configJSON.contentTypeApiForgotPassword,
            method: configJSON.httpPostMethod,
            endPoint: configJSON.setPasswordEndPoint,
            body: JSON.stringify(body),
        });
    };

    resetPasswordSucessCallBack = (response: ResetPassword) => {
        if (response) {
            toast.success(response.message)
            this.props.navigation.navigate("Login")
            localStorage.removeItem("accessToken")
        }
    }

    resetPasswordFailureCallBack = (response: ErrorResponse & ErrorSamePassResponse) => {
        if(response.errors[0].token){
            toast.error(response.errors[0].token)
        }else{
            toast.error(response.error[0])
        }
    };
    // Customizable Area End
}
