import React from "react";
// Customizable Area Start
import {
  Box,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  TextField,
  FormControl,
  FormLabel,
  Typography,
  styled,
  Grid,
  Button,
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import "@mui/styles";
const DatePicker = require("react-datepicker");

import "react-datepicker/dist/react-datepicker.css";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

import CustomisableUserProfilesController, {
  Props,
  IField,
} from "./CustomisableUserProfilesController";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// Customizable Area End

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  getFieldType = (field: IField) => {
    if (
      field.field_type === "integer" ||
      field.field_type === "number" ||
      field.field_type === "float"
    ) {
      return "number";
    }
    return "text";
  };

  renderField = (field: IField, index: number) => {
    if (
      field.field_type === "text" ||
      field.field_type === "string" ||
      field.field_type === "file" ||
      field.field_type === "textarea" ||
      field.field_type === "number" ||
      field.field_type === "integer" ||
      field.field_type === "float"
    ) {
      return (
        <div key={index + "renderField"}>
          <TextField
            data-test-id={field.name}
            label={field.title}
            multiline={field.field_type === "textarea"}
            value={this.state.profile[field.name]}
            onChange={(event) =>
              this.changeFormValue(
                field.name,
                event.target.value,
                field.field_type
              )
            }
            disabled={!field.is_enable}
            required={field.is_required}
            type={this.getFieldType(field)}
            onKeyPress={(event) => this.onKeyPress(event, field)}
            InputLabelProps={{
              shrink: true,
            }}
            // defaultValue='Enter Value'
          />
        </div>
      );
    }

    if (field.field_type === "date" || field.field_type === "datetime") {
      return (
        <div key={index + "renderField"}>
          <div style={{ color: "rgba(0, 0, 0, 0.54)" }}>
            {field.title + (field.is_required ? "*" : "")}
          </div>
          <div style={{ marginBottom: "5px", marginTop: "5px" }}>
            <DatePicker
              data-test-id={field.name}
              selected={
                new Date(
                  this.state.profile[field.name] as string
                ).toString() !== "Invalid Date"
                  ? new Date(this.state.profile[field.name] as string)
                  : null
              }
              onChange={(date: Date) =>
                this.changeFormValue(field.name, date, field.field_type)
              }
              disabled={!field.is_enable}
              required={field.is_required}
            />
          </div>
        </div>
      );
    }

    if (field.field_type === "checkbox") {
      return (
        <div key={index + "renderField"}>
          <Checkbox
            data-test-id={field.name}
            disabled={!field.is_enable}
            checked={this.state.profile[field.name] as boolean}
            onChange={(event) =>
              this.changeFormValue(
                field.name,
                event.target.checked,
                field.field_type
              )
            }
            color="primary"
          />
          {field.title}
        </div>
      );
    }
    if (field.field_type === "boolean") {
      return (
        <div key={index + "renderField"}>
          <FormControl>
            <FormLabel>{field.title}</FormLabel>
            <RadioGroup
              row
              data-test-id={field.name}
              onChange={(event) => {
                this.changeFormValue(
                  field.name,
                  event.target.value,
                  field.field_type
                );
              }}
              value={
                this.state.profile[field.name] === undefined
                  ? undefined
                  : String(this.state.profile[field.name])
              }
            >
              <FormControlLabel
                value={"true"}
                control={<Radio />}
                label="True"
              />
              <FormControlLabel
                value={"false"}
                control={<Radio />}
                label="False"
              />
            </RadioGroup>
          </FormControl>
        </div>
      );
    }
  };

  render() {
    // Customizable Area Start
    const { profileResponse, isEditable, role ,errors } = this.state;
    return (
      <>
          <StyledProfileHeader>
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontSize: "22px",
                color: "#0074BF",
              }}
            >
              Edit Profile
            </Typography>
            <button
              style ={!isEditable || this.hasErrors()?{
                fontFamily: "Montserrat",
                color: "#FFFFFF",
                background: "#00AFEF",
                borderRadius: "20px",
                width: "74px",
                height:"40px",
                textTransform: "none",
                fontWeight: 700,
                fontSize: "14px",
                opacity:0.3,
                border:"none",
              }:{
                fontFamily: "Montserrat",
                color: "#FFFFFF",
                background: "#00AFEF",
                borderRadius: "20px",
                textTransform: "none",
                fontWeight: 700,
                fontSize: "14px",
                width: "74px",
                height:"40px",
                border:"none",
                cursor: "pointer"
              }}
              disabled={!isEditable || this.hasErrors()}
              data-testId = "editProfile"
              onClick={this.editprofileApi}
            >
              Save
            </button>
          </StyledProfileHeader>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <StyledContainer>
              <Box className="upperSection">
                <Box className="profileNameContainer">
                  <Typography sx={webStyle.profileNameLogo}> {this.state.sortName} </Typography>
                </Box>
                {this.state.role === "dpe" ? <Typography className="roleName"> DESIGNATED PILOT EXAMINER </Typography>
                : 
                <Typography className="roleName"> APPLICANT </Typography>
                }
              </Box>
              <Box className="profileFormContainer">
                <Grid container rowGap={2} columnSpacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      display: "flex",
                      gap: "5px",
                      flexDirection: "column",
                    }}
                  >
                    <Typography sx={webStyle.formFieldLabel}>Name</Typography>
                    <StyledProfileTextField 
                    autoComplete="off"
                    variant="outlined"
                    placeholder="Enter full name"
                    value={profileResponse.user_name}
                    data-testId = "nameTestId"
                    onChange={(event) =>
                      this.handleInputChange("user_name", event.target.value)
                    }
                    error={!!errors.user_name}
                    helperText={errors.user_name}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      display: "flex",
                      gap: "5px",
                      flexDirection: "column",
                    }}
                  >
                  <Typography sx={webStyle.formFieldLabel}> {role === "dpe" ? "DMS number" : "FTN"}</Typography>
                  <StyledProfileTextField
                    variant="outlined"
                    autoComplete="off"
                    placeholder={`Enter ${role === "dpe" ? "DMS number" : "FTN"}`}
                    value={role === "dpe" ? profileResponse.dms : profileResponse.ftn}
                    data-testId = "changedms"
                    onChange={(event) =>
                      this.handleInputChange(
                        role === "dpe" ? "dms" : "ftn",
                        event.target.value
                      )
                    }
                    error={!!errors[role === "dpe" ? "dms" : "ftn"]}
                    helperText={errors[role === "dpe" ? "dms" : "ftn"]}
                  />
                   
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      display: "flex",
                      gap: "5px",
                      flexDirection: "column",
                    }}
                  >
                    <Typography sx={webStyle.formFieldLabel}>
                      Phone number
                    </Typography>
                    <StyledProfileTextField placeholder="Enter mobile number" 
                    variant="outlined" 
                    className='infoModalFullName'
                    autoComplete="off"
                    value={this.state.profileResponse.full_phone_number}
                    data-testId = "changeMobileNumber"
                    onChange={(event)=> this.handleInputChange("full_phone_number", event.target.value)}
                    error={!!errors.full_phone_number}
                    helperText={errors.full_phone_number}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      display: "flex",
                      gap: "5px",
                      flexDirection: "column",
                    }}
                  >
                    <Typography sx={webStyle.formFieldLabel}>Email</Typography>
                    <StyledProfileTextField 
                    variant="outlined" placeholder="Enter email"
                    className='infoModalFullName'
                    autoComplete="off"
                    value={this.state.profileResponse.email}
                    data-testId = "changeEmailId"
                    onChange={(event)=> this.handleInputChange("email", event.target.value)}
                    error={!!errors.email}
                    helperText={errors.email}
                    />
                  </Grid>
                </Grid>
                <Box className="DeleteButtonContainer" sx={{}}>
                  <Button
                    startIcon={<DeleteOutlineIcon />}
                    variant="outlined"
                    className="deleteButton"
                  >
                    Delete account
                  </Button>
                </Box>
              </Box>
            </StyledContainer>
          </Box>
      </>
      // Customizable Area End
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const StyledProfileHeader = styled(Box)({
  height: "60px",
  display: "flex",
  padding: "10px 50px 10px 50px",
  justifyContent: "space-between",
  alignItems: "center",
  background: "#ffffff",
  "@media (max-width: 831px)": {
    "box-shadow": " 0px 4px 4px 0px #0000000D",
    zIndex: 2,
  },
});
const StyledContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  height: "fit-content",
  maxWidth: "860px",
  background: "#FFFFFF",
  borderRadius: "20px",
  marginTop: "40px",
  width: "100%",

  "& .upperSection": {
    position: "relative",
    display: "flex",
    borderRadius: "20px  20px 50px 0px",
    height: "110px",
    background: "linear-gradient(180deg, #FFFFFF 0%, #E0F2F8 91%)",

    "& .profileNameContainer": {
      display: "flex",
      height: "120px",
      width: "120px",
      borderRadius: "50%",
      border: "4px solid #E0F2F8",
      justifyContent: "center",
      alignItems: "center",
      top: "50px",
      left: "50px",
      position: "absolute",
      background: "#ffffff",
    },
    "& .roleName": {
      position: "absolute",
      top: 120,
      left: 200,
      fontSize: "14px",
      fontWeight: 700,
      color: "#0074BF",
      textAlign: "center",
      height: "fit-content",
      fontFamily: "Montserrat",
    },
  },
  "& .profileFormContainer": {
    display: "flex",
    padding: "40px",
    marginTop: "100px",
    flexDirection: "column",
    gap: "40px",
  },
  "& .DeleteButtonContainer": {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    "& .deleteButton": {
      borderRadius: "20px",
      background: "#FFFFFF",
      textTransform: "none",
      color: "#F87171",
      border: "1px solid #E2E8F0",
      fontWeight: 700,
      fontSize: "14px",
      fontFamily: "Montserrat",
      "&:hover": {
        border: "1px solid #E2E8F0",
      },
    },
  },

  "@media (max-width: 1246px)": {
    maxWidth: "60vw",
  },
  "@media (max-width: 831px)": {
    maxWidth: "90vw",
  },
  "@media (max-width: 600px)": {
    marginTop: "0px",
    maxWidth: "100%",
    "& .upperSection": {
      borderRadius: "0px  0px 50px 50px",
      flexDirection: "column",
      height: "fit-content",
      justifyContent: "center",
      alignItems: "center",
      padding: "10px",
      "& .profileNameContainer": {
        top: 0,
        left: 0,
        position: "relative",
      },
      "& .roleName": {
        position: "relative",
        top: 0,
        left: 0,
      },
    },
    "& .profileFormContainer": {
      marginTop: "0px",
    },
    "& .DeleteButtonContainer": {
      justifyContent: "center",
      "& .deleteButton": {
        width: "100%",
      },
    },
  },
});
const StyledProfileTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    height: "50px",
    borderRadius: "20px",
    "& fieldset": {
      borderColor: "#CBD5E1",
    },
    "&:hover fieldset": {
      borderColor: "#CBD5E1",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#0074BF",
    },
    "& input": {
      padding: "0 14px",
      height: "100%",
      boxSizing: "border-box",
      fontFamily: "Montserrat",
      fontWeight: 400,
    },
  },
});

const webStyle = {
  profileNameLogo: {
    fontSize: "50px",
    fontWeight: 600,
    color: "#0074BF",
    textAlign: "center",
    height: "fit-content",
    fontFamily: "Montserrat",
  },
  formFieldLabel: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "16px",
    color: "#334155",
  },
};
// Customizable Area End
