import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
import {checkedBox, unCheckedBox,} from "./assets"
const NotifyUsers = [
  {
    name: "Rahul Nikam",
    id: 1,
    image: "https://cdn-icons-png.flaticon.com/512/3135/3135715.png",
  },
  {
    name: "Sanjana Voona",
    id: 2,
    image: "",
  },
  {
    name: "Akshay Hodshil",
    id: 3,
    image: "https://cdn-icons-png.flaticon.com/512/3135/3135715.png",
  },
];
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface CustomToolbarProps {
  label: string;
  onView: (view: string) => void;
  views: string[];
}

interface S {
  // Customizable Area Start
  selectedTime: number;
  selectedDate: string;
  timeSlots: object[];
  serviceProviderId: string;
  serviceProviderSchedule: any;
  details: any;
  token: any;
  date: any,
  view: any,
  open:boolean;
  isCalenderOpen:boolean;
  isSelectAirportOpen:boolean;
  isSelectTestTypeOpen:boolean;
  isNotifiyAll:boolean;
  availabilityDate: string;
  timeSlot: { starttime: string; endtime: string }[];
  timeZones: any[];
  selectedTimeZone: string;
  showtimeZoneOverlay: boolean;
  currentDate:any;
  isSetDetailsOpen: boolean;
  isNotifyContainerOpen: boolean;
  allAirports: any[];
  userSelectedAirports: any[];
  allTests: any[];
  userSelectedTests: any[];
  notifyUsers: any[];
  shouldNotifyAlluser:boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SchedulingController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  serviceProviderDetailApiId: any;
  serviceProviderScheduleApiId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      selectedTime: 0,
      selectedDate: moment().format("YYYY-MM-DD"),
      timeSlots: [],
      serviceProviderId: "",
      serviceProviderSchedule: "",
      details: {},
      token: null,
      date: new Date(),
      view: "month",
      open:false,
    availabilityDate: new Date() as any,
    isCalenderOpen:false,
    isSelectAirportOpen:false,
    isSelectTestTypeOpen:false,
    isNotifiyAll:false,
    timeSlot: [{ starttime: "10:00", endtime: "11:00" }],
    timeZones: [
      "Eastern Standard Time (EST)",
      "Central Standard Time (CST)",
      "Mountain Standard Time (MST)",
      "Pacific Standard Time (PST)",
      "Alaska Standard Time (AKST)",
      "Hawaii-Aleutian Standard Time (HST)",
    ],
    selectedTimeZone: "Eastern Standard Time (EST)",
    showtimeZoneOverlay: false,
    currentDate:new Date(),
    isSetDetailsOpen: false,
    isNotifyContainerOpen:false,
    allAirports: [{service_subcategory_id:1, service_subcategory_name:"KBTF"},{service_subcategory_id:4, service_subcategory_name:"YTSM"}, {service_subcategory_id:3, service_subcategory_name:"ABCD"},{service_subcategory_id:2, service_subcategory_name:"KPUV"}],
    userSelectedAirports: [],
    allTests: [{id:1, designation:"Private - Airplane - Single Engine Land"}, {id:2, designation:"Private - Airplane - Single Engine Land"}],
    userSelectedTests: [],
    notifyUsers: NotifyUsers,
    shouldNotifyAlluser:false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
      this.getServiceProviderDetails({
        setApiCallId: "serviceProviderScheduleApiId",
        serviceProviderId: this.state.serviceProviderId,
        availableDate: this.state.selectedDate,
        token,
      });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && responseJson.errors) {
        this.setState({ serviceProviderSchedule: [] });

        if (typeof responseJson.errors === "string") {
          this.showAlert("", responseJson.errors);
        } else {
          this.parseApiErrorResponse(responseJson);
          this.parseApiCatchErrorResponse(errorReponse);
        }
      } else if (apiRequestCallId != null) {
        if (apiRequestCallId === this.serviceProviderScheduleApiId) {
          this.setState({
            serviceProviderSchedule:
              responseJson && responseJson.data ? responseJson.data : "",
          });
        }
        if (apiRequestCallId === this.serviceProviderDetailApiId) {
          let msg = new Message(getName(MessageEnum.NavigationCalendarMessage));
          msg.addData(
            getName(MessageEnum.CalendarProviderDetailsResponseMessage),
            responseJson
          );
          this.send(msg);
          this.unsubscribeMessages();
          return;
        }
      }
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      runEngine.debugLog("Availability Message Received", message);
      const serviceProviderIdMessage = message.getData(
        getName(MessageEnum.ServiceProviderIdMessage)
      );
      if (serviceProviderIdMessage) {
        this.setState({
          serviceProviderId: serviceProviderIdMessage,
        });
      }

      const CalendarProviderDetailsApiMessage = message.getData(
        getName(MessageEnum.CalendarProviderDetailsApiMessage)
      );
      if (CalendarProviderDetailsApiMessage) {
        this.getServiceProviderDetails({
          ...CalendarProviderDetailsApiMessage,
          setApiCallId: "serviceProviderDetailApiId",
        });
        return;
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  onSelectDate = (selectedDateStr: string) => {
    const { details } = this.state;
    this.setState({
      selectedDate: selectedDateStr,
      timeSlots: [],
      selectedTime: 0,
    });
    this.getServiceProviderAvailability(selectedDateStr, details);
  };

  calendarProps = {
    minDate: moment(),
    onSelectDate: (selectedDate: string) => this.onSelectDate(selectedDate),
  };

  formatDate = (date: Date = new Date()) => {
    return moment(date).format("dddd, MMMM D, YYYY");
  };

  handleAllCheckIcon() {
    if (
      this.state.userSelectedAirports.length === this.state.allAirports.length 
      && this.state.userSelectedAirports.length > 0 
      && this.state.allAirports.length
    ) {
      return checkedBox;
    }
    return unCheckedBox;
  }

  getColorForNotifyUser() {
    if (this.state.isNotifyContainerOpen) {
      return "#0074BF";
    }

    return "#475569";
  }

  toggleCalenderOpen() {
    this.setState({
      isCalenderOpen: !this.state.isCalenderOpen,
      showtimeZoneOverlay: false
    });
  }

  toggleShowtimeZoneOverlay() {
    this.setState({
      showtimeZoneOverlay: !this.state.showtimeZoneOverlay,
      isCalenderOpen: false
    });
  }

  toggleSelectAirport() {
    this.setState({
      isSelectAirportOpen: !this.state.isSelectAirportOpen,
      isSelectTestTypeOpen: false,
      isNotifyContainerOpen: false,
    });
  }

  toggleSelectTest() {
    this.setState({
      isSelectTestTypeOpen: !this.state.isSelectTestTypeOpen,
      isSelectAirportOpen: false,
      isNotifyContainerOpen: false,
    });
  }

  toggleNotifyUserContainer() {
    this.setState({
      isNotifyContainerOpen: !this.state.isNotifyContainerOpen,
      isSelectAirportOpen: false,
      isSelectTestTypeOpen: false,
    });
  }

  handleAddTimeSlot() {
    this.setState((prevState) => ({
      timeSlot: [
        ...prevState.timeSlot,
        { starttime: "10:00", endtime: "11:00" },
      ],
    }));
  }

  handleDelete(indexToDelete: number) {
    this.setState((prevState) => ({
      timeSlot: prevState.timeSlot.filter(
        (_, index) => index !== indexToDelete
      ),
    }));
  }

  handleUpdateTimeSlot = (
    index: number,
    key: keyof S["timeSlot"][0],
    value: string
  ): void => {
    this.setState((prevState) => {
      const updatedTimeSlots = [...prevState.timeSlot];
      updatedTimeSlots[index][key] = value;
      return { timeSlot: updatedTimeSlots };
    });
  };

  toggleSelectAllAirports() {
    const { userSelectedAirports, allAirports } = this.state;

    if (userSelectedAirports.length === allAirports.length) {
      this.setState({ userSelectedAirports: [] });
    } else {
      this.setState({ userSelectedAirports: allAirports });
    }
  }

  toggleSelectAllTests() {
    const { userSelectedTests, allTests } = this.state;

    if (userSelectedTests.length === allTests.length) {
      this.setState({ userSelectedTests: [] });
    } else {
      this.setState({ userSelectedTests: allTests });
    }
  }

  handleAirportCheckbox(serviceSubcategoryId: number) {
    const isChecked = this.state.userSelectedAirports.some(
      (airport) => airport.service_subcategory_id === serviceSubcategoryId
    );

    return isChecked;
  }

  handleSelectAirport(item: any) {
    const { userSelectedAirports } = this.state;

    const index = userSelectedAirports.findIndex(
      (airport) =>
        airport.service_subcategory_id === item.service_subcategory_id
    );

    let updatedUserSelectedAirports;
    if (index === -1) {
      updatedUserSelectedAirports = [...userSelectedAirports, item];
    } else {
      updatedUserSelectedAirports = userSelectedAirports.filter(
        (airport) =>
          airport.service_subcategory_id !== item.service_subcategory_id
      );
    }

    this.setState({ userSelectedAirports: updatedUserSelectedAirports }, () => {
      console.log(
        "Updated userSelectedAirports:",
        this.state.userSelectedAirports
      );
    });
  }
  timePickerInputRefs: { [key: string]: HTMLInputElement | null } = {};
  formatSelectedTest = (values: any[]) => {
    if (values.length === 1) {
      return values[0].designation; 
    }
    return `${values[0].designation}, +${values.length - 1} more`;
  };

  formatSelectedAirports = (values: any[]) => {
    if (values.length === 1) {
      return values[0].service_subcategory_name; 
    }
    if (values.length === 2) {
      return `${values[0].service_subcategory_name}, ${values[1].service_subcategory_name}`; 
    }
    if (values.length === 3) {
      return `${values[0].service_subcategory_name}, ${values[1].service_subcategory_name}, ${values[2].service_subcategory_name}`; 
    }
    return `${values[0].service_subcategory_name}, ${values[1].service_subcategory_name}, ${values[2].service_subcategory_name} +${values.length - 3} more`;
  };

  handleSelectTests(item: any) {
    const { userSelectedTests } = this.state;

    const index = userSelectedTests.findIndex((tests) => tests.id === item.id);

    let updatedUserSelectedTests;
    if (index === -1) {
      updatedUserSelectedTests = [...userSelectedTests, item];
    } else {
      updatedUserSelectedTests = userSelectedTests.filter(
        (tests) => tests.id !== item.id
      );
    }

    this.setState({ userSelectedTests: updatedUserSelectedTests }, () => {
      console.log(
        "Updated userSelectedAirports:",
        this.state.userSelectedAirports
      );
    });
  }

  shouldEnableCreateAvailablityBtn(){
    return true
  }

  handleAllCheckIconforTests() {
    if (this.state.userSelectedTests.length === this.state.allTests.length) {
      return checkedBox;
    }
    return unCheckedBox;
  }

  handleTestCheckbox(id: number) {
    const isChecked = this.state.userSelectedTests.some(
      (tests) => tests.id === id
    );

    return isChecked;
  }

  
  async getServiceProviderAvailability(selectedDateStr: any, profileData: any) {
    const token = this.state.token || "";
    if (profileData && profileData.id && selectedDateStr) {
      this.getServiceProviderDetails({
        setApiCallId: "serviceProviderScheduleApiId",
        serviceProviderId: profileData.id,
        availableDate: moment(selectedDateStr).format("YYYY/MM/DD"),
        token,
      });
    } else if (this.state.serviceProviderId && selectedDateStr) {
      this.getServiceProviderDetails({
        setApiCallId: "serviceProviderScheduleApiId",
        serviceProviderId: this.state.serviceProviderId,
        availableDate: moment(selectedDateStr).format("YYYY/MM/DD"),
        token,
      });
    }
  }

  async getServiceProviderDetails(dataObj: any) {
    const { setApiCallId, serviceProviderId, availableDate, token } = dataObj;

    const header = {
      "Content-Type": configJSON.applicationJsonApiContentType,
      token,
    };

    this.apiCall({
      setApiCallId,
      header,
      method: configJSON.getApiMethodType,
      endPoint: `${configJSON.serviceProviderAPiEndPoint}?availability_date=${availableDate}&service_provider_id=${serviceProviderId}`,
      body: null,
    });
    return true;
  }

  apiCall = async (data: any) => {
    const { setApiCallId, header, endPoint, method, body } = data;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (setApiCallId === "serviceProviderDetailApiId") {
      this.serviceProviderDetailApiId = requestMessage.messageId;
    } else if (setApiCallId === "serviceProviderScheduleApiId") {
      this.serviceProviderScheduleApiId = requestMessage.messageId;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  unsubscribeMessages = () => {
    runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
  };
  // Customizable Area End
}
