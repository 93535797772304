// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  currentPassword:string,
  showPassword: boolean;
  showCurrentPassword:boolean;
  showConfirmPassword: boolean;
  password: string;
  confirmPassword: string;
  currentpassword:string;
  passwordStrength: number;
  errors: {
    [key: string]: string;
  };
  isValid: boolean | void;
  changePassword:any;
  alertType: AlertType;
  alertMsg: string;
  isAlert: boolean;

}

interface SS {
  id: any;
}
export interface PasswordRequirement {
  text: string;
  regex?: RegExp;
  test?: (value: string) => boolean;
}
type AlertType = 'success' | 'error' | 'warning' | 'info';

export default class SettingChangePasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  changePasswordApiCallId: string="";
  passwordRequirements: PasswordRequirement[];
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [ 
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      currentPassword:"",
      showPassword: false,
      showCurrentPassword:false,
      showConfirmPassword: false,
      password: "",
      confirmPassword: "",
      passwordStrength: 0,
      errors: {},
      isValid: false,
      currentpassword:"",
      changePassword:'',
      alertType: 'success'  as AlertType,
      alertMsg: '',
      isAlert: false,

    };
    this.passwordRequirements = [
      { text: "At least one capital letter", regex: /[A-Z]/ },
      { text: "At least one lowercase letter", regex: /[a-z]/ },
      { text: "At least one number", regex: /\d/ },
      { text: "At least one special character", regex: /[!@#$%^&*(),.?":{}|<>]/ },
      { text: "Minimum character length is 8 characters", test: (value: string) => value.length >= 8 }
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
      );

       if(apiRequestCallId === this.changePasswordApiCallId){
        this.setState({changePassword:responseJson.message,
          isAlert: true,
          alertType: responseJson.message === "Your password has been successfully reset." ? "success" : "error",
  alertMsg: responseJson.message === "Your password has been successfully reset."
    ? "Your password has been updated."
    :"Incorrect current password.",
          })
       }
       
        this.resetFields();
    
       
  }
  }
  togglePasswordVisibility = (field: 'showPassword' | 'showConfirmPassword' | 'showCurrentPassword') => {

    this.setState((prevState) => ({
      ...prevState,
      [field]: !prevState[field]
    }));
  }
  resetFields = () => {
    this.setState({
      currentpassword: '',
      password: '',
      confirmPassword: '',
      isValid: false,
      errors: {},
    });
  };
  getPasswordRequirementIconStyle = (requirement: PasswordRequirement, password: string) => {
    const webStyle = {
      checkIcon: {
        color: '#34D399',
        fontSize: '16px',
        marginRight: '8px',
      },
      uncheckIcon: {
        color: '#9E9E9E',
        fontSize: '16px',
        marginRight: '8px',
      },
    };
    const isMet = requirement.regex
      ? requirement.regex.test(password)
      : requirement.test
        ? requirement.test(password)
        : true;
    return isMet ? webStyle.checkIcon : webStyle.uncheckIcon;
  }
  handleInputChange = (field: keyof S, value: string | boolean) => {
    
    this.setState((prevState) => {
      const newState = { ...prevState, [field]: value };
      
      
      
      if (field === 'password') {
        this.checkPasswordStrength(value as string);
        if (newState.confirmPassword) {
          this.validateField('confirmPassword', newState.confirmPassword);
        }
        if (newState.currentpassword) {
          this.validateField('currentpassword', newState.currentpassword);
        }
      }
      this.validateField(field, value);
      this.checkFormValidity(newState);

      console.log("currentpassword",this.state.password)
      return newState;
    });
  }
  checkPasswordStrength = (password: string) => {
    let strength = 0;
    if (password.length >= 8) strength++;
    if (password.match(/[a-z]+/)) strength++;
    if (password.match(/[A-Z]+/)) strength++;
    if (password.match(/\d+/)) strength++;
    if (password.match(/[$@#&!]+/)) strength++;

    this.setState({ passwordStrength: strength });
  }
  validateField = (field: keyof S, value: string | boolean) => {
    this.setState(prevState => {
      const errors = { ...prevState.errors };

      switch (field) {
      
        case 'password':
          const meetsRequirements = this.passwordRequirements.every(req => 
            req.regex ? req.regex.test(value as string) : req.test ? req.test(value as string) : true
          );
          errors.password = meetsRequirements ? '' : 'Password does not meet requirements';
          if (prevState.confirmPassword) {
            errors.confirmPassword = prevState.confirmPassword === value ? '' : 'Passwords do not match. Please try again';
          }
          break;

        case 'confirmPassword':
          errors.confirmPassword = value === prevState.password ? '' : 'Passwords do not match. Please try again';
          break;

       
      }

      return { errors };
    });
  }
  async componentDidMount() {
    super.componentDidMount();
    // this.createAccount()
  }
  createAccount = () => {
    localStorage.getItem('accessToken')
    let token= localStorage.getItem('accessToken')
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const httpBody = {
          "old_password": this.state.currentpassword,
          "new_password": this.state.password,
          "new_password_confirmation": this.state.confirmPassword
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.changePasswordApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts/change_password"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  checkFormValidity = (currentState: S) => {
    const isValid = 
     
      currentState.password.length >= 8 &&
      this.checkConformPassword(currentState) &&
     
      this.passwordRequirements.every(req => 
        req.regex ? req.regex.test(currentState.password) : req.test ? req.test(currentState.password) : true
      );

      this.setState({ isValid });
  }
  checkConformPassword = (currentState: S): boolean => {
    if(currentState.password === currentState.confirmPassword){
     return true
    }else{
      return false
    }
  };
  oncloseAlert = () => {
    this.setState({ isAlert: false });
};
 
}

// Customizable Area End