// Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  styled,
  Grid
} from "@mui/material";

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; 
import './calendarStyles.css';
import {calendar1,plusIcon,left,right,calendar2} from './assets'
import BigCalender from './BigCalender.web'

import SchedulingController, {
  Props,
} from "./SchedulingController";

export default class SchedulingAvailability extends SchedulingController {
  constructor(props: Props) {
    super(props);
  }
  handleDateChange = (selectedDate:any) => {
    this.setState({ date: selectedDate });
  };

  render() {
    return (
        <>
        <Box sx={webStyle.headerContainer}>
          <Typography sx={webStyle.headerText}>Change password</Typography>
          <Box style={{display:'flex',alignItems:'center',gap:'20px'}}>
          <Typography sx={webStyle.headerText1}>Sync calendars</Typography>
          
            
          <Typography sx={webStyle.headerText1}>
          
          <img src={calendar1} style={{width:'21px',height:'21px'}}/>
            Today
            </Typography>
          <Typography sx={webStyle.headerText2}>
          <img src={plusIcon} style={{width:'21px',height:'21px'}}/>
            New availability</Typography>
          </Box>
        </Box>
    <Grid container spacing={2} style={{padding:'50px 50px 50px 50px'}} >
      <Grid item xs={8} sm={4} lg={4} md={4} >
        
        <Box>
        <div style={calendarStyles}>
              <Calendar onChange={this.handleDateChange} value={this.state.date} />
            </div>    
          </Box>



        <StyledContainer style={{padding:'20px 24px 20px 24px'}}>
          <Box style={{display:'flex',justifyContent:'space-between',width:'100%'}}>

            <Box style={{color:'#001C55',fontWeight:700,fontSize:'16px',fontFamily:'Montserrat'}}>
        Upcoming booked slots
            </Box>
        <Box style={{display:'flex',alignItems:'center',gap:'20px'}}>
        <img src={left} style={{width:'20px',height:'20px'}}/>
        <img src={right} style={{width:'20px',height:'20px'}}/>
        </Box>
        </Box>

        <Box style={{display:'contents'}}>
        <img src={calendar2} style={{width:'60px',height:'60px',marginTop:'80px',marginBottom:'20px'}}/>
        <Typography sx={webStyle.text}>Nothing booked yet!</Typography>
        <Typography sx={webStyle.text1}>Once your availabilities are booked,<br/> they'll appear here.</Typography>
        </Box>
        </StyledContainer>
      </Grid>

      <Grid item xs={8} sm={8} lg={8} md={8}>
        <StyledContainerBigCal>
          <BigCalender navigation={this.props.navigation} id={""}/>
        </StyledContainerBigCal>
      </Grid>
  
    </Grid>
        </>
    );
  }
}


const StyledContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  // height: "fit-content",
  alignItems: "center",
  // maxWidth: "clamp(400px, 50vw, 860px)",
  background: "#ffffff",
  borderRadius: "20px",
  marginTop: "40px",
  // width: "100%",
  // padding: "20px",

  "& .form": {
    width: "100%",
    maxWidth: "80%",
    gap: "15px",
    flexDirection: "column",
    // minWidth:"400px",
    display: "flex",

    "& .fieldContainer": {
      flexDirection: "column",
      gap: "5px",
      display: "flex",
      "& .fieldLabel": {
        color: "#334155",
        fontSize: "16px",
        fontFamily: "Montserrat",
        fontWeight: 600,
      },
    },

    "& .saveButton": {
      opacity: "30%",
      padding: "0px",
      height: "50px",
      fontSize: "16px",
      fontWeight: 700,
      fontFamily: "Montserrat",
      textTransform: "none",
      borderRadius: "20px",
      color: "#ffffff",
      background: "#0074BF",
    },
  },

  "@media (max-width: 900px)": {
    maxWidth: "clamp(300px, 90vw, 800px)",
  },
  "@media (max-width: 600px)": {
    // maxWidth: "100%",
    // height:"100%",
    "& .form": {
      maxWidth: "100%",
      minWidth:"290px",
    }
  }

});
const StyledContainerBigCal = styled(Box)({
  // height: "fit-content",
  // maxWidth: "clamp(400px, 50vw, 860px)",
  // marginTop: "40px",
  // width: "100%",
  alignItems: "center",
  flexDirection: "column",
  background: "#ffffff",
  display: "flex",
  borderRadius: "20px",
  padding: "30px 30px 30px 30px",

  "& .form": {
    // minWidth:"400px",
    maxWidth: "80%",
    gap: "15px",
    flexDirection: "column",
    width: "100%",
    display: "flex",

    "& .fieldContainer": {
      flexDirection: "column",
      display: "flex",
      gap: "5px",
      "& .fieldLabel": {
        color: "#334155",
        fontSize: "16px",
        fontWeight: 600,
        fontFamily: "Montserrat",
      },
    },

    "& .saveButton": {
      height: "50px",
      fontSize: "16px",
      padding: "0px",
      fontWeight: 700,
      fontFamily: "Montserrat",
      textTransform: "none",
      opacity: "30%",
      borderRadius: "20px",
      color: "#ffffff",
      background: "#0074BF",
    },
  },

  "@media (max-width: 900px)": {
    maxWidth: "clamp(300px, 90vw, 800px)",
  },
  "@media (max-width: 600px)": {
    // maxWidth: "100%",
    // height:"100%",
    "& .form": {
      maxWidth: "100%",
      minWidth:"290px",
    }
  }

});
const calendarStyles = {
   '--react-calendar-weekdays-decoration': 'none',
   color: '#64748B',
   overflow: 'hidden',
   borderRadius: '20px', 
  border: 'none',
 
};



const webStyle = {
  headerContainer: {
    display: "flex",
    height: "60px",
    padding: "10px 50px 10px 50px",
    alignItems: "center",
    background: "#ffffff",
    justifyContent:"space-between"
  },
  headerText: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "22px",
    color: "#0074BF",
  },
  text: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "15px",
    color: "#94A3B8",
    marginBottom:'5px'
  },
  text1: {
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "13px",
    color: "#94A3B8",
    marginBottom:'80px',
    textAlign:'center'
  },
  headerText1: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "14px",
    color: "#00AFEF",
    border:'2px solid #00AFEF',
    borderRadius:'20px',
    padding:'6px 20px 6px 20px',
    display:'flex',
    gap:'5px'
  },
  headerText2: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "14px",
    color: "white",
    border:'2px solid #00AFEF',
    borderRadius:'20px',
    padding:'6px 20px 6px 20px',
    display:'flex',
    backgroundColor:'#00AFEF',
    gap:'5px'
  },
};
// Customizable Area End
