import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import React from "react";
import { toast } from "react-toastify";

interface AccountStatus {
    activated: boolean;
}
interface ApiResponse {
    success: boolean;
    message: string;
    data: AccountStatus;
    error: null;
}
interface ErrorObject {
    token: string;
}

interface ErrorResponse {
    errors: ErrorObject[];
}

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isActivated: boolean;
    checkStatus: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: number;
    // Customizable Area End
}

export default class AccountStatusController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    loginApiCallId: string = "";
    getUserStatusApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            // Customizable Area End
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        this.state = {
            isActivated: false,
            checkStatus: false

        };
        // Customizable Area End
    }

    // Customizable Area Start
    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson && !responseJson.errors) {
                this.responseSucessCell(apiRequestCallId, responseJson)
            }
            else if (responseJson && responseJson.errors) {
                this.responseFailureCell(apiRequestCallId, responseJson)
            }
        }
    }

    userStatusApiCall = async (valueData: {
        contentType?: string;
        method?: string;
        endPoint?: string;
        body?: {};
        apiType?: string;
        type?: string;
    }) => {
        const token = await (localStorage.getItem('accessToken'))
        let { contentType, method, endPoint, body, apiType } = valueData;
        let header = {
            "Content-Type": contentType,
            Token: token
        };
        let userStatusRequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        userStatusRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        userStatusRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        body &&
        userStatusRequestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                apiType === "" ? JSON.stringify(body) : body
            );
            userStatusRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(userStatusRequestMessage.id, userStatusRequestMessage);
        return userStatusRequestMessage.messageId;
    };

    responseSucessCell = async (apiRequestCallId: string, responseJson: ApiResponse) => {
        if (apiRequestCallId === this.getUserStatusApiCallId) {
            this.userStatusApiSucessCallBack(responseJson);
        }
    };

    responseFailureCell = async (apiRequestCallId: string, responseJson: ErrorResponse) => {
        if (apiRequestCallId === this.getUserStatusApiCallId) {
            this.userStatusApiFailureCallBack(responseJson);
        }
    };

    userStatusApi = async () => {
        this.getUserStatusApiCallId = await this.userStatusApiCall({
            contentType: configJSON.contentTypeApiForgotPassword,
            method: configJSON.httpGetMethod,
            endPoint: configJSON.checkStatusEndPoint
        })
    };

    userStatusApiSucessCallBack = (response: ApiResponse) => {
        if (response.data.activated) {
            this.setState({ isActivated: true })
        } else {
            this.setState({ checkStatus: true })
        }
    };

    userStatusApiFailureCallBack = (response: ErrorResponse) => {
        toast.error(response.errors[0].token)
    };

    handleSubmit = () => {
        this.setState((state) => ({ ...state, isActivated: !this.state.isActivated }));
    }
    handleGetStarted = (event: React.FormEvent<HTMLFormElement>) => {
        this.props.navigation.navigate('AccountSetup')
    }

    handleLogoutBoard = () => {
        localStorage.removeItem("role");
        localStorage.removeItem("accessToken");
       this.props.navigation.navigate("Login")
    };
    // Customizable Area End
}
