import React from 'react';
import { Box } from '@mui/material';
import { logOutIcon } from './../assets';

interface LogOutButtonProps {
  handleLogout: () => void
}

class LogOutButton extends React.Component<LogOutButtonProps> {
  render() {
    return (
        <Box 
         sx={styles.LogOutButton} 
        component="button"
        onClick={this.props.handleLogout}
      >
       
        Log out
        <img 
            data-testid="logout-icon"
            style={{marginLeft:'10px'}} 
          src={logOutIcon.default} 
          width={'16px'} 
          height={'16px'} 
          alt="upload"
        />
      </Box>
    );
  }
}
export default LogOutButton;
const styles = {
    LogOutButton: {
      fontFamily: 'Montserrat, sans-serif',
      padding: '6px 20px',
      borderRadius: '20px',
      color: '#0074BF',
      cursor: 'pointer',
      fontSize: '14px',
      fontWeight: 700,
      border: '2px solid #0074BF',
      display: 'flex',
      alignItems: 'center',
      width: '125px',
      height:'40px',
      backgroundColor: 'transparent',  // Added to ensure button has transparent background
      '&:hover': {
        backgroundColor: 'rgba(14, 165, 233, 0.04)'  // Optional: adds hover effect
      }
    },
  };