// Customizable Area Start
import React from "react";
import {
  
  MenuItem, Select,  FormControl,IconButton
} from "@mui/material";

import './calendarStyles.css';
import "react-calendar/dist/Calendar.css";
import "react-big-calendar/lib/css/react-big-calendar.css"; 
import { Calendar, momentLocalizer } from "react-big-calendar";
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import {est,day,day3,week,month,schedule} from './assets'

import moment from "moment";

import SchedulingController, {
  Props,
} from "./SchedulingController";



export default class BigCalender extends SchedulingController {
  constructor(props: Props) {
    super(props);
  }
  CustomToolbar = ({ label, onView, onNavigate,views }:any) => {
    const viewIcons :any= {
      day: day,
      "3days": day3,
      week: week,
      month: month,
      schedule: schedule,
    };
    return (
      <div style={{ marginBottom: "20px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>

<div >
        <div className="custom-label" >
          {label}
        </div>
        <div style={{fontSize:'12px',fontFamily:'#94A3B8',color:'#94A3B8',display:'flex',gap:'10px',marginTop:'9px'}}>
          <img src={est} style={{width:'14px',height:'14px'}}/>
          Eastern Standard Time (EST)
          </div>

          </div>
        <div style={{display:'flex',}}>
        <IconButton onClick={() => onNavigate("PREV")} aria-label="Previous month">
            <KeyboardArrowLeftOutlinedIcon />
          </IconButton>

          
          <IconButton onClick={() => onNavigate("NEXT")} aria-label="Next month">
            <KeyboardArrowRightOutlinedIcon />
          </IconButton>
          
        <FormControl  >
          
          <Select
          data-test-id="handleViewChange"
            value={this.state.view}
            onChange={this.handleViewChange}
            IconComponent={KeyboardArrowDownOutlinedIcon}
            className="custom-dropdown"
          >
            {views.map((viewOption:any) => (
              <MenuItem key={viewOption} value={viewOption} className="custom-menu-item">
                 <div style={{ display: "flex", alignItems: "center", fontSize:'14px',
  fontFamily:'Montserrat',
  color:'#3C3E49'  }}>
                    <img
                      src={viewIcons[viewOption] || month} 
                      style={{ width: "16px", height: "16px", marginRight: "8px" }}
                      alt={viewOption}
                    />
                    {viewOption.charAt(0).toUpperCase() + viewOption.slice(1)}
                  </div>
              </MenuItem>
            ))}
            <MenuItem  className="custom-menu-item">
            <div style={{ display: "flex", alignItems: "center" }}>
                  <img src={day3} style={{ width: "16px", height: "16px", marginRight: "8px" }} alt="3 Days" />
                  3 Days
                </div>
              </MenuItem>
              
          </Select>
        </FormControl>
        </div>
      </div>
    );
  };
  handleViewChange = (event:any) => {
    this.setState({ view: event.target.value });
  };
  render() {
    const localizer = momentLocalizer(moment);

    // Sample events array
    const events = [
      {
        title: "Meeting",
        start: new Date(),
        end: new Date(),
        allDay: false,
        className: "scheduled",
      },
    ];
    return (
        
        
      <div>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        view={this.state.view} 
        onView={(view) => this.setState({ view })} 
        components={{
          toolbar: this.CustomToolbar, 
        }}
        style={{ height: 500, backgroundColor: "white", borderRadius: "10px" }}
        data-test-id="startAccessor"
      />
    </div>

       
  
    
    );
  }
}


// Customizable Area End
